$main-color: #00C7E7;
$secondary-color: #1C1D2E;
$featured-color: #FB8500;
$error-color: #EF233C;
$warning-color: #FFB703;
$success-color: #04D973;
$white-color: #ffffff;

$text-color: #777782;
$background-color: #EDEEEE;

$gray-color-1: #EDEEEE;
$gray-color-2: #D4D6D7;

.text-button.buy-audience-link {
  // color: $main-color;
  background-color: inherit;
  cursor: pointer;
  margin-bottom: 3rem;
  font-size: 13px;

  i {
    position: relative;
    top: 1px;
    margin-left: -1rem;
    margin-right: 1rem;
  }
}

.fluid .buy-audience {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  color: $secondary-color;
  text-align: left;

  .buy-audience-custom {
    margin-top: 5rem;
    .buy-audience-link {
      cursor: pointer;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 300;
    color: $secondary-color;
    margin-bottom: 3rem;
  }

  h3 {
    margin-top: 0;
    font-size: 20px;
    font-weight: 500;
  }

  p {
    font-size: 12px;
    margin-bottom: 2.5rem;
  }

  .content-widget {
    @media only screen and (max-width: 980px) {
      border-left: 0;
      border-right: 0;
    }
  }

  &-left {
    flex: 1;

    &-description {

      @media only screen and (max-width: 980px) {
        margin: 0 5rem;
      }

      h2 {
        span {
          cursor: pointer;
        }

        i {
          color: inherit;
          font-size: 14px;
          padding-left: 1rem;
          vertical-align: middle;
        }
      }

      p {
        font-size: 14px;
      }
    }

    .content-widget.paid-audience {
      padding: 5rem;
      margin-bottom: 5rem;

      button {
        font-size: 12px;
      }

      p:first-of-type {
        margin-bottom: 0;
      }

      .paid-audience-rules {
        margin-top: 3rem;
        display: flex;

        .col {
          margin: 0;
          display: flex;
          flex-direction: column;
          margin-right: 5rem;
          text-align: center;

          &:last-child {
            margin-right: 0;
          }

          &-icon {
            border: 1px $gray-color-2 solid;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin: 2rem 0;
            height: 134px;
            cursor: pointer;
          }

          .value span:first-of-type {
            font-weight: 600;
          }

          .rules-icon {
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: auto;
              height: 60px;
            }
          }
        }
      }

      .arrow-group {
        display: flex;
        max-width: 608px;
        justify-content: space-around;

        .arrow {
          display: flex;
          justify-content: center;
          margin-top: 1rem;
          margin-right: 5rem;

          &:last-child {
            margin-right: 0;
          }
        }

        .arrow-up {
          width: 0;
          height: 0;
          border-left: 18px solid transparent;
          border-right: 18px solid transparent;
          border-bottom: 18px solid $gray-color-1;
        }
      }

      .paid-audience-rules-details {
        background-color: $gray-color-1;
        padding: 4rem;
        margin: 0 -5rem -5rem -5rem;

        .input.invalid {
          .input-row {
            .item {
              border-color: #EE3636;
            }
          }
        }

        .field {
          margin-bottom: 0;
        }

        .input label {
          margin-bottom: 2rem;
          display: block;
        }

        .select-item .field:first-child {
          margin-right: 2rem;
        }

        .input-row {
          display: flex;
          justify-content: center;
          align-items: baseline;
          border: 0px $gray-color-2 solid;

          .input {
            margin: 0 1rem;
            width: 100%;
          }

          .select select{
            max-width: 300px;
          }

          .item {
            text-align: center;
            display: flex;
            align-items: center;
            border: 1px $gray-color-2 solid;
            margin-left: -1px;
            flex-grow: .33;
            background-color: $white-color;

            p {
              margin: 0 auto;
              line-height: 46px;
              color: #1C1D2E;
              font-family: inherit;
              font-size: 13px;
              font-weight: 500;
            }
          }

          .item:first-child {
            margin-left: 0;
          }

          .item.active {
            position: relative;
            z-index: 2;
            border-color: #00CDEE;
          }

          .item.active,
          .item:hover {
            p {
              color: #00CDEE;
            }
            cursor: pointer;
          }
        }
      }
    }

    .paid-audience-responses {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: initial;
      }

      .number-responses {
        border: 1px $gray-color-2 solid;
        width: 115px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        box-sizing: border-box;
        border-radius: 2px;

        @media only screen and (max-width: 1024px) {
          align-self: center;
        }
      }

      .range-responses {
        flex-grow: 1;
        margin-left: 4rem;
        font-size: 12px;
        color: rgba($secondary-color, .6);

        @media only screen and (max-width: 1024px) {
          margin-left: 0;
          margin-top: 5rem;
        }

        &-slider {
          -webkit-appearance: none;
          width: 100%;
          height: 16px;
          border-radius: 9px;
          background-color: $gray-color-2;
          background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, $main-color), color-stop(100%, $main-color));
          background-size: 4.5% 100%;
          background-repeat: no-repeat;
          outline: none;
          opacity: 0.7;
          -webkit-transition: .2s;
          transition: opacity .2s;

          &:hover {
            opacity: 1;
          }

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $secondary-color;
            cursor: pointer;
          }

          &::-moz-range-thumb {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $secondary-color;
            cursor: pointer;
          }
        }

        &-steps {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &-right {
    width: 280px;
    margin-left: 6rem;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      margin: 0;
    }

    .text-flex {
      display: flex;
      justify-content: space-between;
    }

    .promo {
      border: 2px dashed $featured-color;
      padding: 0 3rem;
      color: $secondary-color;

      p:first-child {
        font-weight: 600;
        margin-bottom: 1rem;
      }

      p:last-child {
        margin-top: 0;

        span:first-child {
          font-weight: 600;
          color: $featured-color;
        }
      }
    }

    .promo-text span {
      color: $featured-color;
    }

    .featured-text p {
      font-weight: 600;
      font-size: 14px;
    }

    .indent1 {
      padding-left: 2rem;
      font-weight: 600;
      color: rgba($secondary-color, .6);

      .text-flex:first-child p {
        margin-bottom: 0;
      }
    }

    &-total {
      border-bottom: 1px solid $gray-color-1;
      padding-bottom: 1rem;

      h2 {
        margin-top: 0;
        margin-bottom: 1.1rem;
      }
      p {
        margin-top: 0;
        color: rgba($secondary-color, .6);
      }
    }

    &-answers {

      .indent1 .text-flex:first-child p{
        margin-top: 0;
      }
    }

    &-summary {
      border-top: 1px solid $gray-color-1;
      border-bottom: 1px solid $gray-color-1;
      color: rgba($secondary-color, .6);
      padding-bottom: 1rem;

      .featured-text:first-child p {
        color: $secondary-color;
        margin-bottom: 0;
      }

      .indent1 {

        .text-flex p:last-child {
          color: $secondary-color;
        }
      }

      .indent2 {
        padding-left: 4rem;

        p:first-child {
          max-width: 208px;
        }
      }
    }

    .buy-button {
      width: 100%;
      margin: 5rem 0;

      @media only screen and (max-width: 1024px) {
        height: 50px;
      }
    }
  }
}