$main-color: #00C7E7;
$secondary-color: #1C1D2E;
$featured-color: #FB8500;
$error-color: #EF233C;
$warning-color: #FFB703;
$success-color: #04D973;
$white-color: #ffffff;

$text-color: #777782;
$background-color: #EDEEEE;

$gray-color-1: #EDEEEE;
$gray-color-2: #D4D6D7;
// #D4D6D7
// #D0D2D3

$metrics-compare-a: #FF9900;  
$metrics-compare-b: #7000FF;

html {
  color: $text-color;
  font-weight: 300;
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

body .margin-bottom-4 {
  margin-bottom: 4rem;
}

.content-wrapper h2 {
  font-weight: 400;
}

.margin-top-8 {
  margin-top: 8rem;
}

.app, .main-wrapper {
  min-height: 100%;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.hidden {
  display: none !important;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

@font-face {
  font-family: 'sounditi';
  src: url('../assets/fonts/fontello/font/sounditi.eot?92458470');
  src: url('../assets/fonts/fontello/font/sounditi.eot?92458470#iefix') format('embedded-opentype'),
       url('../assets/fonts/fontello/font/sounditi.woff?92458470') format('woff'),
       url('../assets/fonts/fontello/font/sounditi.ttf?92458470') format('truetype'),
       url('../assets/fonts/fontello/font/sounditi.svg?92458470#sounditi') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: "sounditi";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #7f7d86;
  font-size: 16px;
}

html {
  font-size: 5px;
  height: 100%;
}

body {
  background: $background-color;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Sarabun', sans-serif;
  
  font-size: 13px;
  // font-weight: 400;
  height: 100%;
  width: 100% !important;
}

b {
  font-weight: 500;
  color: $secondary-color;
}

.message-warning {
  box-sizing: border-box;
  width: 100%;
  background-color: $warning-color;
  padding: 0 4rem;
  margin-top: -5rem;
  margin-bottom: 2rem;

  i {
    margin-top: -4px;
    margin-right: 4rem;
    color: $white-color;
  }

  h3 {
    color: $white-color;

    i {
      margin-top: 0;
      margin-right: 0;
      margin-left: 1rem;
    }
  }

  p {
    color: $white-color;
  }
}

.save-warning-modal, .delete-link-modal, .delete-account-modal {
  .warning-text {
    margin-top: 0;

    span{
      font-size: 14px;
    }
  }

  .last-text {
    margin-top: 5rem;
  }

  i {
    display: inline-block;
    width: 28px;
    text-align: left;

    &.icon-error {
      color: $error-color;
    }

    &.icon-warning-empty {
      color: $warning-color;
    }

    &.icon-ok-squared {
      color: $success-color;
    }
  }
}

.save-warning-modal {
  .icon-text {
    font-weight: 500;
    margin-top: 4rem;
    margin-bottom: -2rem;
  }

  i {
    &::before {
      margin: 0;
    }
  }
}

.delete-link-modal, .delete-account-modal {
  .icon-text {
    padding-left: 3rem;
  }
}

.incompatible-screen-message.login-box {
  .incompatible-screen {
    width: 80px;
    margin: auto;
    margin-bottom: 8rem;
    margin-top: 12rem;
  }

  h2 {
    margin-bottom: 4rem;
  }
}

.button-toggle:hover {
  cursor: pointer;
}

.button-toggle {
  height: 18px;
  width: 36px;
  background: $gray-color-2;
  border: 1px $gray-color-2 solid;
  border-radius: 10px;

  .handle {
    border-radius: 8px;
    background: $white-color;
    width: 16px;
    height: 16px;
    margin: 1px;
  }
}

.button-toggle.active {
  background: $main-color;

  .handle {
    margin-left: 19px;
  }
}

.button-toggle.disabled {
  cursor: default;
  pointer-events: none;
  opacity: .6;
}

.login-box.signup {
  // .button:not(.main-button) {
  //   background: transparent;
  //   color: #EDEDF1;
  //   margin-bottom: 12rem;
  //   margin-top: 6rem;
  // }
}

.login-box {
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 12rem;

  .less-top-margin {
    margin-top: -4rem;
  }

  h2 {
    color: $secondary-color;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 6rem;
  }

  .register-box {
    border-top: 1px solid $gray-color-2;
    margin-top: 6rem;
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
  }

  .logo {
    width: 80px;
    margin: 12rem auto 4rem;
  }

  .register-box .button:not(.main-button) {
    /*
    margin-top: 5rem;
    margin-bottom: 12rem;
    display: block;
    width: 100%;
    background: #EDEDF1;
    color: $secondary-color;
    font-weight: 500;
    */
  }

  .register-box .button:not(.main-button):hover {
    /*
    background: #4b4b54;
    color: #EDEDF1;
    */
  }

  .button:not(.main-button) {
    // background: transparent;
    // color: #EDEDF1;
    // margin-bottom: 12rem;
    // margin-top: 4rem;
  }

  .button:not(.main-button):hover {
    // color: #4aa1fb;
  }

  p {
    color: #7f7d86;
    margin: 0 0 6rem;
    font-size: 15px;
  }

  p.last {
    margin-bottom: 2rem;
    margin-top: -3rem;
    /* font-weight: 600; */
  }
}

.field {
  text-align: left;
  margin-bottom: 4rem;
  position: relative;
}

.field.last {
  margin-bottom: 0;
}

.field label {
  display: inline-block;
  color: $secondary-color;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 2rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

.field .input-wrapper input {
  flex: 1;
  width: 100%;
  background: $white-color;
  height: 32px;
  font-size: 13px;
  color: $secondary-color;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  padding: 0 3rem;
  overflow: hidden;
  outline: 0;
  border-radius: 2px;
  border: 1px $gray-color-2 solid;
}

.field .input-wrapper input:not(:read-only):hover,
.field select:hover {
  border-color: $text-color;
}

.field .input-wrapper input:not(:read-only):focus,
.field select:focus {
  border-color: $main-color;
}

.field .input-wrapper input:read-only {
  /* color: $gray-color-2; */
}

.field .input-wrapper .textarea-wrapper {
  flex: 1;
  width: 100%;
  background: $white-color;
  padding: 2.4rem 3rem 1.6rem;
  border-radius: 2px;
  border: 1px $gray-color-2 solid;
}

.field.invalid .input-wrapper .textarea-wrapper {
  border: 1px $error-color solid;
}

.field .input-wrapper textarea {
  flex: 1;
  width: 100%;
  font-size: 12px;
  color: $secondary-color;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  padding: 0;
  overflow: hidden;
  outline: 0;
  resize: initial;
}

.field select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1;
  width: 100%;
  background: $white-color;
  height: 32px;
  font-size: 13px;
  color: $secondary-color;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  padding: 0 6rem 0 3rem;
  overflow: hidden;
  outline: 0;
  border-radius: 2px;
  border: 1px $gray-color-2 solid;
}

.field .select:after {
  content: '\e93e';
  font-family: "sounditi";
  font-size: 15px;
  line-height: 32px;
  color: $secondary-color;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  pointer-events: none;
}

.field input::placeholder {
  color: #7f7d86;
}

.field .input-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  transition: border 0.2s;
}

/*
.field  .draggable-list .input-wrapper {
  border: 1px solid #1c1c26;
}

*/

.field.invalid .input-wrapper input {
  border-color: $error-color;
}

.field .input-wrapper .input-icon {
  position: absolute;
  background: $gray-color-2;
  top: -1px;
  right: -1px;
  bottom: -1px;
  width: 41px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 0 2px 2px 0;
}

.search .input-wrapper .input-icon {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
}

.field .input-wrapper .input-text {
  background: $gray-color-2;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  color: $text-color;
  padding: 0 12px;
  box-sizing: border-box;
  min-width: 40px;
  border-radius: 0 2px 2px 0;
}

.field .input-wrapper .input-icon i {
  width: 100%;
}

.field .row {
  margin-left: -2rem;
  width: calc(100% + 4rem);
}

.field .col {
  margin: 0 2rem;
}

.field .col label {
  font-size: 12px;
}

.field.disabled {
  cursor: default !important;
  :not(label) {
    opacity: 0.6;
  }
}

.field.picker.read-only {
  input {
    opacity: .3;
    cursor: text !important;
  }

  .input-icon {
    pointer-events: none;
    background: rgba($gray-color-2, .6);
  }
}

.section-modules .field.disabled {
  pointer-events: none;
}

.field .disabled a {
  cursor: default !important;
}

.field.text input {
  border-radius: 2px 0 0 2px;
  padding: 0 3rem;
}

.field.text input:read-only {
  color: $gray-color-2;
}

.field .field {
  margin-bottom: 0;
}

.field.no-selectable .input-wrapper {
  pointer-events: none;
}

.field.no-selectable input {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-remember {
  display: flex;
  align-items: center;
}

.check-remember p {
  color: #7f7d86;
  margin: 0;
  font-size: 11px;
}

.check-wrapper {
  display: flex;
  align-items: center;
}

.check {
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 3rem;
  top: 0;
  left: 0;
  margin: 0;
  box-sizing: content-box;
}

.check .checkmark {
  position: absolute;
  top: 2px;
  left: 3px;
  height: 12px;
  width: 12px;
  background-color: #eee;
  background: transparent;
  border: 1px solid #7f7d86;
  border-radius: 3px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check input:checked ~ .checkmark:after {
  display: block;
}

.check .checkmark:after {
  left: 3px;
  top: -4px;
  width: 5px;
  height: 11px;
  border: solid $main-color;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.text-with-contact-link a {
  color: $featured-color;
}

.buttons-wrapper {
  position: relative;
  display: flex;
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: 8rem;

  .button {
    margin: 0 2rem;
    height: 42px;
    font-size: 13px;
  }
}

.button {
  display: inline-block;
  background: $main-color;
  color: #FFFFFF;
  text-decoration: none;
  /* border-radius: 6px; */
  border-radius: 2px;
  border-radius: 0px;
  height: 32px;
  box-sizing: border-box;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 32px;
  padding: 0 4rem;
  outline: 0;
  border: 0;

  .button-text {
    position: relative;
    text-align: center;
    margin: auto;
    margin-top: 2px;
    margin-top: 0px;

    span {
      margin: auto;
    }

    i {
      position: relative;
      top: 1px;
    }
  }

  i {
    color: #EDEDF1;
    font-size: 16px;
    margin-left: -1rem;
    margin-top: -1px;
    margin: 0;
    width: auto;
    display: inline-block;
  }

  i.icon-plus-1 {
    font-size: 16px;
    margin-left: -1rem;
    margin-top: -1px;
  }

  span {
    margin: 0 1rem;
  }

  .rai-activity-indicator {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.button.disabled {
  opacity: 0.6;
}

.button.main-button.disabled {
  background: $gray-color-2;
}

button.secondary {
  background: transparent;
  color: $text-color;
  border: 1px $text-color solid;
  i {
    color: inherit;
  }
}

button.no-border {
  border: 0;
}

button.no-padding {
  padding: 0;
}

button.no-padding-left {
  padding-left: 0;
}

button.margin-bottom {
  margin-bottom: 3rem;
}

button.full-width,
.full-width {
  display: block;
  flex: 1;
  width: 100%;
}

button:disabled {
  opacity: 0.6;
  cursor: default;
}

button:not(:disabled):hover {
  filter: brightness(110%);
  cursor: pointer;
}

button.secondary:not(:disabled):hover {
  filter: brightness(140%);
}

.button:not(:disabled):active {
  filter: brightness(90%);
}

button.button-red {
  border: none;
  background: $error-color;
  color: $white-color;
}

button.featured {
  background: $featured-color;
}

button.inline.link {
  color: $main-color;
  padding: 0;
}

button.inline.link.link-paid {
  color: $featured-color;
}

button.white {
  background: $white-color;
  color: $secondary-color;
}

.text-button {
  display: inline-block;
  padding: 2rem;
  color: #7f7d86;
  text-decoration: none;
  box-sizing: border-box;
  font-size: 11px;
  transition: all 0.1s;
}

.text-button:hover {
  color: #A7A6A9;
}

button.inline {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 2rem 1rem;
}

button.inline:hover {
  cursor: pointer;
}

button:disabled.inline {
  opacity: 0.4;
}

/* Login screen */

.screen-login {
  /* background: $secondary-color; */
}

.screen-login .check-remember {
  margin-top: 4rem;
  margin-bottom: 10rem;
}

.screen-login .text-button {
  margin-top: 5rem;
}

.screen-login .button {
  /*
  display: block;
  width: 100%;
  */
  margin-bottom: 4rem;
}

.screen-login .check-wrapper {
  padding-right: 8px;
}

.screen-login .last {
  margin-bottom: 6rem;
}

/* Layout */

.app {

}

.sidebar {
  position: relative;
  width: 282px;
  width: 300px;
  padding-top: 21rem;
  display: flex;
  flex-direction: column;
}

.sidebar .sidebar-content {
  flex: 1;
}

.sidebar .sidebar-bottom .menu {
  padding-top: 5rem;
  margin-bottom: 5rem;
  border-top: 2px #25232B solid;
}

.sidebar .sidebar-bottom .menu a,
.sidebar .sidebar-bottom .menu button {
  padding: 0;
}

.sidebar .sidebar-bottom .menu .icon-container {
  background: #676970;
}

.sidebar .sidebar-bottom .menu .icon-container i {
  color: #15121A;
}

.sidebar {
  .logo-wrapper {
    border-bottom: 2px #25232B solid;
    margin: 0 4rem;
  }

  .logo {
    position: absolute;
    top: 6rem;
    left: 6rem;
    width: 90px;
  }
}

.sidebar .menu {
  margin: 0 4rem 7rem;
}

.sidebar div:not(.sidebar-bottom) .menu:first-child {
  margin-top: 6rem;
}

.sidebar div:not(.sidebar-bottom) .menu:last-child {
  padding-bottom: 4rem;
}

.sidebar .menu h2 {
  font-size: 14px;
  font-weight: 400;
  color: #7f7d86;
  margin-bottom: 4rem;
}

.sidebar .menu a,
.sidebar .menu button {
  display: flex;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 7px 8px;
  text-decoration: none;
  margin-bottom: 3rem;
  width: 100%;
  background: transparent;
  border: 0;
  outline: 0;

  span {
    /*
    background: #f08c1e;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    text-transform: lowercase;
    padding: 0px 6px 2px;
    border-radius: 13px;
    position: relative;
    top: -8px;
    left: 6px;
    */
  }

  span.step {
    top: 0px;
    left: 10px;
    left: 5px;
    border: 1px #7f7d86 solid;
    color: #7f7d86;
    background: transparent;
    font-size: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    box-sizing: border-box;
    display: inline-block;
    padding: 0;
    border-radius: 13px;
  }
}

span.soon {
  background: #f08c1e;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  text-transform: lowercase;
  padding: 0px 6px 2px;
  border-radius: 13px;
  position: relative;
  top: -8px;
  left: 6px;
}

.sidebar .menu button.active {
  span.step {
    border: 1px #ededf1 solid;
    color: #ededf1;
  }
}

.sidebar .menu a .icon-container,
.sidebar .menu button .icon-container {
  width: 30px;
  height: 30px;
  background: #2B2933;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.sidebar .menu a .icon-container i,
.sidebar .menu button .icon-container i {
  color: #7f7d86;
  font-size: 15px;
  line-height: 15px;
  flex: 1;
}

.sidebar .menu a p,
.sidebar .menu button p {
  margin: 0;
  margin-left: 4rem;
  color: #7f7d86;
  font-size: 15px;
  line-height: 30px;
}

.sidebar .menu a.active,
.sidebar .menu button.active {
  background: #262127;
}

.sidebar .menu a:not(.active):not(.disabled):hover p,
.sidebar .menu a:not(.active):not(.disabled):hover i,
.sidebar .menu button:not(.active):not(.disabled):hover p,
.sidebar .menu button:not(.active):not(.disabled):hover i,
.sidebar .menu button:not(.active):not(.disabled):hover .step {
  color: #EDEDF1;
  border-color: #EDEDF1;
}

.sidebar .menu button.disabled:hover,
.sidebar .menu button.disabled {
  pointer-events: none;
}

.sidebar .menu a.active .icon-container,
.sidebar .menu button.active .icon-container {
  background: #278DF3;
}

.sidebar .menu a.active .icon-container i,
.sidebar .menu button.active .icon-container i {
  color: #EDEDF1;
}

.sidebar .menu a.active p,
.sidebar .menu button.active p {
  color: #EDEDF1;
}

.sidebar .menu a .icon-container .icon-flash-3,
.sidebar .menu button .icon-container .icon-flash-3 {
  font-size: 17px;
}

.sidebar .menu button:hover {
  cursor: pointer;
}

.sidebar .submenu .menu {
  margin-bottom: 0;
  overflow: hidden;
  transition: all 0.4s;
  height: auto;
  margin-right: 0;
}

.sidebar .submenu .menu.group button:not(:last-child) {
  margin-bottom: 2rem;
}

.sidebar div:not(.sidebar-bottom) .submenu .menu:last-child {
  padding-bottom: 0;
}

.sidebar .submenu .menu button .icon-container {
  background: none;
}

.sidebar .submenu .menu button p {
  margin-left: 2rem;
}

.sidebar .submenu.fold > button i:before {
  content: '\ecbd';
}

.sidebar .submenu.fold .menu {
  height: 0px;
}

.app.white {
  background: $white-color;
}

.tabs {
  flex: 1;
  height: 72px;
  position: relative;
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
}

.tab {
  position: relative;
  top: 1px;
  height: 100%;
  border-radius: 12px 12px 0 0;
  z-index: 1;
}

.tab-inner {
  height: 100%;
  padding: 0 9rem;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  color: #7f7d86;
  font-size: 16px;
}

.tab-inner i.icon-pencil-6 {
  margin-left: 5px;
}

.tab-inner a,
.tab-inner button {
  text-decoration: none;
  color: #7f7d86;
}

.only-icon .tab-inner {
  padding: 0 3.5rem;
}

.only-icon .tab-inner i {
  font-size: 26px;
  position: relative;
  top: -2px;
}

.button-close-tab {
  opacity: 0.4;
  text-decoration: none;
  margin-left: 2rem;
  margin-right: -5rem;
  margin-top: 3px;
}

.button-close-tab i:before {
  font-size: 22px;
}

.active.tab {
  background: linear-gradient(0deg, #2B2A33 calc(100% - 8px), transparent 100%);

}

.active .tab-inner {
  background: #201D25;
}

.create-test-modal {
  .test-type {
    display: flex;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    &>div {
      margin-right: 4rem;

      @media (max-width: 1024px) {
        margin-right: 0;
        margin-bottom: 4rem;
      }
    }
    
    &>div:last-child {
      margin-right: 0;

      @media (max-width: 1024px) {
        margin-bottom: 0;
      }
    }

    &-box {
      display: flex;
      align-items: center;
      border-radius: 2px;
      border: 1px solid $gray-color-2;
      padding: 3rem 4rem;
      cursor: pointer;
      flex: 1 1;

      &.active {
        border: 1px solid $main-color;
      }

      &-icon {
        margin-right: 5rem;
        margin-left: 1rem;

        i {
          font-size: 4rem;
          line-height: 7rem;

          &:before {
            margin: 0;
          }
        }
      }

      &-content {
        .content-option {
          /* display: flex; */
          align-items: center;
          margin-bottom: 2rem;

          p {
            margin: 0;
            font-weight: 500;
          }
        }

        .content-description {
          font-size: 12px !important;
          margin-bottom: 0;
        }
      }
    }
  }
}

.screen-topbar {
  background: $secondary-color;
  background: linear-gradient(90deg, #0E4465 -0.28%, #0e1d26 104.34%);
  height: 60px;
  display: flex;
  padding-left: 4rem;

  .logo {
    width: 60px;
    cursor: pointer;
  }

  .right-content {
    align-items: center;
  }

  button.inline {
    position: relative;
    color: $white-color;
    height: 70px;
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    margin: 0 4rem;
  }

  button.inline:hover:before,
  button.inline.active:before {
    content: '';
    position: absolute;
    background: #ffffff;
    width: 100%;
    height: 3px;
    bottom: -12px;
    bottom: 5px;
    left: 0;
    right: 0;
  }

  .main-links {
    margin-left: 4rem;
  }

  .main-buttons {
    button {
      margin-right: 4rem;

      &.button-with-loader:disabled {
        opacity: 1;
        color: #ffffff;

        .button-text { 
          margin-top: 0;
        }
      }
    }
  }

  .user-options {
    position: relative;

    button {
      color: $white-color;
      margin-left: 2rem;

      i {
        color: $white-color;
        font-size: 13px;
        margin-left: 1rem;
        margin-top: 2px;
      }

      .button-text {
        display: flex;
      }

      .user-email {
        max-width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.screen-topbar .user-options-menu,
.user-options-menu,
.test-options-menu {
  position: absolute;
  background: #ffffff;
  border: 1px #D4D6D7 solid;
  color: #777782;
  z-index: 4;
  right: 4rem;
  top: 12rem;
  text-align: left;
  width: 160px;
  padding: 2rem 0;
  box-shadow: 0 0 8px #00000030;

  button,
  button.inline {
    height: inherit;
    margin: 0;
    width: 100%;
    font-size: 12px;
    padding: 2.5rem 4rem;
    text-align: left;
    color: $text-color;
    line-height: inherit;
  }

  button:hover {
    color: $main-color;
  }

  .separator {
    margin: 1rem 4rem;
    padding: 0;
    border-bottom: 1px $gray-color-1 solid;
  }
}

.download-box.content-widget {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 0;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  right: 20rem;

  .download-box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 3rem 4rem;
    border-bottom: 1px solid $gray-color-2;
    box-sizing: border-box;
    min-width: 200px;

    i {
      font-size: 13px;
      color: $gray-color-2;
      cursor: pointer;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .download-box-body {
    max-height: 200px;
    overflow-y: auto;

    .download-box-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 3rem 4rem;
      box-sizing: border-box;

      span:first-child {
        flex: 1 1;
      }

      &.hidden {
        display: none;
      }

      .progress-bar {
        width: 100px;
        margin-left: 2rem;
        margin-right: 2rem;
        height: 3rem;
      }
    }
  }
}

.screen-content-header {
  color: $secondary-color;
  background: $white-color;
  border-bottom: 1px $gray-color-2 solid;
}

.screen-content {
  position: relative;
  padding-bottom: 12rem;
}

.screen-content.add-padding-top {
  padding-top: 12rem;
}

.screen-metrics {
  /* background: $secondary-color; */

  .screen-content {
    // padding-bottom: 0;

    .scrollview {

    }

    .screen-content-inner {
      display: flex;

      .metrics {
        flex: 1;
        padding: 4rem 4rem;
        background: $secondary-color;
      }

      .panel {
        position: relative;
        border-left-color: #33333C;
        background: $secondary-color;

        .fixed-top {
          border-bottom-color: #33333C;
        }
      }
    }
  }
}



.screen-content-inner {
  background: $background-color;
  height: 100%;
  overflow: auto;
  width: 100%;
  position: relative;

  .loading-cover {
    position: absolute;
    z-index: 10;
  }
}

#scrollview {
  height: 100%;
  width: 100%;
  overflow: auto;
}

body .scrollbar-track {
  z-index: 3;
}

body .scrollbar-track-y {
  right: 6px;
  background: transparent;
}

body .scrollbar-track-x {
  bottom: 6px;
  background: transparent;
}

body .scrollbar-track-y .scrollbar-thumb,
body .scrollbar-track-x .scrollbar-thumb {
  background: #464750;
}

.content-wrapper {
  box-sizing: border-box;
  max-width: 980px;
  margin: auto;
  margin-bottom: 12rem;

  &.only-title {
    margin-bottom: 5rem;
  }

  & > .loading-cover {
    top: 182px;
  }
}

.loading-cover.have-topbar {
  top: 182px;
}

.content-wrapper.full-height {
  & > .loading-cover {
    top: 60px;
  }
}

.content-wrapper.big {
  max-width: 1200px;
}

.content-wrapper.medium {
  max-width: 1024px;
}

.content-wrapper.small-medium {
  max-width: 890px;
}

.content-wrapper.small {
  max-width: 730px;
}

.content-wrapper.large {
  max-width: 1200px;
}

.float-right {
  float: right;
}

.account-item {
  flex: 1;
  margin-bottom: 6rem;

  .name-item {

  }

  .language-item {

  }

  .link {
    margin-left: 2rem;
    font-size: 12px;
    font-weight: 400;
  }

  .title {
    font-size: 13px;
    font-weight: 500;
    color: $text-color;
    margin: 0 0 1.5rem 0;
  }

  .value {
    font-size: 15px;
    font-weight: 400;
    color: $secondary-color;
    margin: 0;
  }

  .subtitle {
    margin: 1rem 0 0 0;
    font-size: 13px;
    font-weight: 400;
    color: $text-color;
  }

  .subtitle.no-verificated {
    font-size: 15px;
    font-weight: 500;
    color: $error-color;
  }

  .plan-progress {
    .title {
      font-weight: 400;
      color: $secondary-color;
    }

    .progress-bar {
      width: 100%;
      height: 6px;
      border-radius: 3px;
      background: $gray-color-1;

      .progress {
        max-width: 100%;
        width: 10%;
        height: 6px;
        border-radius: 3px;
        background: $featured-color;
      }
    }

    .subtitle {
      b {
        font-size: 18px;
        color: $secondary-color;
      }

      .float-right {
        margin-top: 5px;
      }
    }
  }
}

.delete-account {
  text-align: right;
  margin-top: -6rem;
  margin-bottom: 12rem;

  button {
    color: $error-color;
    &:hover {
      opacity: .8;
    }
  }
}

.content-header {
  background: $white-color;
  margin-bottom: 12rem;
  border-bottom: 1px $gray-color-2 solid;

  .content-header-title {
    padding: 0 4rem 0 4rem;
    border-bottom: 1px $gray-color-1 solid;
    display: flex;

    h1 {
      font-size: 24px;
      font-weight: 300;
      line-height: 70px;
      margin: 0;
      // flex: 1;
    }
  }

  .right-content {
    margin-left: 3rem;
    display: flex;
    text-align: right;
    align-items: center;

    button {
      margin-left: 3rem;
    }

    i {
      color: inherit;
    }

  }

  .content-header-menu {
    padding: 0 4rem 0 4rem;

    .content-menu {
      button {
        padding: 0;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        height: 50px;
        line-height: 50px;
        margin-right: 6rem;
      }

      button.active {
        color: $main-color;
        border-bottom: 3px $main-color solid;
      }

      button:hover {
        color: $main-color;
      }

      i {
        color: $gray-color-2;
        margin-left: -3rem;
        margin-right: 3rem;
      }
    }
  }

  .content-header-demo {
    height: 42px;
    background: $background-color;      
    border-top: 1px $gray-color-2 solid;
    padding: 0 4rem;

    .btn-toggle-media-preview {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .label {
        font-size: 13px;
        font-weight: 500;
        line-height: 30px;
        margin-right: 2rem;
      }

      i {
        font-size: 20px;
      }
    }

    &.show-demo-data {
      background-color: #F39F4D;
      background-image: linear-gradient(135deg, #e28a33 5.56%, #F39F4D 5.56%, #F39F4D 50%, #e28a33 50%, #e28a33 55.56%, #F39F4D 55.56%, #F39F4D 100%);
      background-size: 11px 11px;
      color: $white-color;
    }
  }

  .content-header-autosave {
    position: relative;
    top: 1px;

    .icon-with-text {
      display: flex; 
      height: 70px;
      align-items: center;
      color: $text-color;

      &.saved-message {
        color: $success-color;
      }

      &.saving-message {
        color: $warning-color;
      }

      i {
        font-size: 17px;
        margin-right: 1rem;
        position: relative;
        top: 0px;
      }

      p {
        font-weight: 600;
        font-size: 12px;
        position: relative;
      }

      .icon-arrows-cw {
        font-size: 14px;
        transform: rotate(45deg);
      }

      .icon-arrows-ccw {
        font-size: 15px;
        transform: rotate(45deg);
      }
    }

    .autosave-cloud-icon {
      position: relative;
      font-size: 17px;
      line-height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .autosave-status-icon {
        position: absolute;
        top: 2px;
        left: 7px;
        font-size: 7px;
        line-height: 3rem;
      }
    }
  }
}

.section-modules {
  width: 300px;
  background: $background-color;
  border-right: 1px $gray-color-2 solid;
  position: fixed;
  top: 182px;
  left: 51px;
  bottom: 0;
  display: flex;
  flex-direction: column;

  .section-title {
    padding: 5rem 6rem 6rem;
    background: $white-color;
    border-bottom: 1px $gray-color-2 solid;

    h2 {
      margin: 0;
      font-size: 15px;
      font-weight: 500;
      color: $secondary-color;
    }

    p {
      margin: 2rem 0 0rem;
    }
  }

  .content-header {
    margin-bottom: 0;

    .content-header-menu {
      padding: 0 6rem;

      button {
        height: 42px;
      }
    }
  }
}

.sidebar-options {
  padding: 4rem 0;
  overflow: auto;
  position: relative;

  .loading-cover {
    position: absolute;

    .cover {
      background: $white-color;
      opacity: 0.6;
      backdrop-filter: blur(2px);
    }
  }

  .field {
    padding: 0 6rem 5rem;
    border-bottom: 1px $gray-color-2 solid;
    display: flex;
    flex-direction: column;

    .row {
      margin-left: 0;
      width: 100%;
    }

    p {
      margin: 0 auto 5rem;
    }

    .small-select {
      right: 6rem;

      &.disabled {
        pointer-events: none;
        opacity: .6;
      }
    }

    label {
      font-size: 15px;
      font-weight: 500;
      color: $text-color;
      margin-bottom: 3rem;
    }

    .field {
      padding: 0;
      border-bottom: 0;
      margin-bottom: 3rem;

      label {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .field:last-child,
    p:last-child {
      margin-bottom: 0rem;
    }
  }
}

.design-menu {
  width: 50px;
  background: $white-color;
  border-right: 1px $gray-color-1 solid;
  position: fixed;
  top: 182px;
  left: 0;
  bottom: 0;

  .sections-menu {
    display: flex;
    flex-direction: column;
    height: 100%;

    .sections {
      flex: 1;
    }

    .button {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 0;
      background: transparent;
      border-bottom: 1px $gray-color-1 solid;

      i {
        margin: auto;
        font-size: 18px;
        color: $secondary-color;
      }
    }

    .button.toggle-sections {
      background: $gray-color-2;
      color: $secondary-color;
    }

    .button:hover {
      cursor: pointer;
      background: $gray-color-1;

      i {
        color: $main-color;
      }
    }

    .button.active {
      background: $main-color;

      i {
        color: $white-color;
      }
    }
  }
}

.row {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  h1 {
    margin: 0;
  }
}

.row.two-columns {
  max-width: 600px;
}

.col {
  flex: 1;
  margin: 0 3rem;
}

.col-no-flex {
  margin: 0 3rem;
  min-width: 280px;
  flex: 0.5;
  position: relative;

  .phone-preview {
    top: 0;
  }
}

.col-phone {
  padding-right: 6rem;
  position: relative;
  padding: 6rem 6rem 6rem 0;
  box-sizing: content-box;
}

.col-phone.show-scrollbars {
  padding: 6rem 6rem 6rem 4rem;

  .phone-preview {
    left: 4rem;
  }
}

.max-width-two-col {
  max-width: 570px;
}

.two-columns .col {
  /* flex: 0.5; */
}

h1 {
  font-size: 24px;
  font-weight: 300;
  color: $secondary-color;
  margin: 0 0 5rem 0;
}

.film-name {
  font-size: 24px;
  font-weight: 300;
  color: $secondary-color;
  margin: -4rem 0 11rem;
}

.content-wrapper.recopilator {
  .segment-name {
    margin: 0;
    border: 1px $gray-color-2 solid;

    &.segment-b {
      margin-top: 6rem;
    }
  }

  &.film-metrics {
    position: relative;

    .controls {
      position: absolute;
      color: #fff;
      text-shadow: 0 0 7px black;
      right: 4rem;
      top: 13rem;
      font-size: 15px;
      z-index: 2;

      span {
        padding: 1rem 2rem;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .tooltip-info {
      /* background: #00000030; */
      position: absolute;
      width: 200px;
      top: 10rem;
      left: 1rem;
      text-shadow: 0 0 7px black;
      color: #fff;
      padding: 3rem;

      .emotional-overview {
        background: none;
        border: 0;
        height: 160px;
      }
    }

    video {
      width: 100%;
    }

    .video-timeline {
      .segment-name {
        display: none;
      }

      &:hover {
        .position-bar.mouse {
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    .times {
      pointer-events: none;
    }

    .position-bar {
      width: 2px;
      background: #00C7E7;
      position: absolute;
      height: 224px;
      bottom: -5px;
      z-index: 4;
      pointer-events: none;

      &.mouse {
        opacity: 0;
        transition: opacity 0.2s;
        background: #00C7E750;
      }

      .time {
        background: #00C7E7;
        color: #ffffff;
        border-radius: 2px;
        padding: 2px 1rem;
        display: inline-block;
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
    }

    .blink-timeline {
      margin-top: -1px;
      height: 15px;

      .timeline {
        border-radius: 0;

        .times {
          z-index: 2;
          height: 215px;

          > div:not(.featured) {
            /* display: none; */
            opacity: 0;
          }

          .featured {
            background-color: #D4D6D760;
            height: 100%;
          }

          .label {
            top: 216px;
          }
        }

        .track {
          border-radius: 0;

          &:hover {
            .popup {
              opacity: 1;
            }
          }
        }

        .popup {
          opacity: 0;
        }
      }
    }

    .emotional-overview {
      background: $white-color;
      border: 1px $gray-color-2 solid;
      margin: 0;
      height: 100px;
      position: relative;

      h2 {
        position: absolute;
        font-size: 12px;
        font-weight: 500;
        z-index: 2;
        left: 4rem;
        bottom: 0rem;
      }
    }

    .player {
      position: relative;
      min-height: 300px;
      background: $gray-color-1;
      display: flex;
      align-items: center;
    }

    .play-message {
      color: $white-color;
      font-size: 20px;
      text-shadow: 0 0 7px black;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    color: $secondary-color;
    margin-bottom: 4rem;
  }

  p {
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    h2:not(.total-responses), p {
      padding: 0 3rem;
    }
  }
}

.global-summary-widget {
  margin-bottom: 10rem;

  h2 {
    margin-top: 0;
  }
}

.content-widget {
  background: $white-color;
  box-sizing: border-box;
  padding: 5rem 5rem 0 5rem;
  margin-top: 3rem;
  margin-bottom: 12rem;
  border: 1px $gray-color-2 solid;
  border-radius: 2px;

  /*
  p {
    color: $text-color;
    font-size: 13px;
    margin: 0;

    a, button {
      color: $main-color;
      text-decoration: none;
      padding: 0;
    }
  }
  */
}

.have-invitation-waiting-send {
  background: #06c7e7;
  color: #ffffff;
  font-weight: 300;
  padding: 4rem 4rem;
  margin-bottom: 3rem;
  // border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 14px;

  h3 {
    margin: 0;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }

  p {
    margin: 0;
    font-size: 15px;
  }

  .have-invitation-waiting-send-content {
    flex: 1;
  }

  .have-invitation-waiting-send-buttons {
    // margin-right: 2rem;

    button {
      color: $white-color;
      border-color: $white-color;
      height: 48px;
    }

    button.main {
      border: 1px $white-color solid;
    }
  }
}

.content-widget.link, .content-widget.summary, .content-widget.defineAudience {
  padding: 0;
  margin-bottom: 5rem;
  position: relative;

  &.compact {
    .summary {
      height: 110px;

      .data-quality {
        .title {
          // margin-top: 2rem;
        }

        .value-meter {
          height: 35px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
  
          .label {
            font-weight: 500;
  
            &.poor {
              color: #FC0C1B;
            }
    
            &.regular {
              color: #FECB45;
            }
    
            &.good {
              color: #68C53E;
            }
          }
  
          .quality-meter {
            background: $gray-color-1;
            width: calc(100% - 20rem);
            display: flex;
            height: 15px;
            margin-right: 2rem;
    
            .poor {
              background-color: #FC0C1B;
            }
    
            .regular {
              background-color: #FECB45;
            }
    
            .good {
              background-color: #68C53E;
            }
          }
        }

        .legend {
          margin-top: -1rem;
          font-size: 10px;
          font-weight: 500;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px $gray-color-2 solid;
      }

      .col {
        cursor: default;
        justify-content: center;

        &.featured .value {
          font-size: 25px;
        }

        &:first-child:not(.featured) {
          border-right: 1px #EDEEEE solid;
        }

        .title {
          font-size: 11px;
        }

        .value {
          font-size: 20px;
          font-weight: 500;
          text-transform: capitalize;

          &.green {
            color: #68C53E;
          }

          &.red {
            color: #FC0C1B;
          }
        }

        .separator {
          margin: 0 2rem;
          color: $secondary-color;
          opacity: 0.2;
          font-size: 21px;
        }
  
        .value-a {
          color: $metrics-compare-a;  
        }

        .value-b {
          color: $metrics-compare-b;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    border-right: 0;
    border-left: 0;
  }

  .loading-cover {
    position: absolute;
  }

  .loading-cover .cover {
    background: #ffffff;
    opacity: 0.6;
  }

  .alias input:hover {
    /* cursor: pointer; */
  }

  .alias, .link {
    padding: 3rem 4rem;
    display: flex;
    align-items: center;
    border-bottom: 1px $gray-color-2 solid;

    @media (max-width: 1024px) {
      padding: 1rem 3rem;
    }

    input {
      font-size: 24px;
      font-weight: 300;
      border: 0;
      outline: 0;
      flex: 1;
      margin-right: 3rem;
      min-width: 0;
      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }

    button {
      @media (max-width: 1024px) {
        padding: 0 3rem;
      }
    }

    i {
      font-size: 26px;
      color: $secondary-color;
      margin-right: 2rem;
      @media (max-width: 1024px) {
        font-size: 20px;
      }
    }

    .active {
      display: flex;

      .label {
        color: $secondary-color;
        font-size: 15px;
        font-weight: 400;
        margin-right: 3rem;
      }
    }
  }

  .alias {
    .alias-text {
      flex: 1 1;
      display: flex;

      .alias-name {
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;

          i {
            visibility: visible;
          }
        }

        span {
          font-size: 24px;
          font-weight: 300;
          color: initial;
          margin-right: 1rem;

          @media (max-width: 1024px) {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 20px;
            max-width: 45vw;
          }
        }
      }

      .alias-buttons {
        display: flex;
        height: 46px;
        width: 46px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          opacity:.6
        }
      }

      i {
        font-size: 17px;
        line-height: 17px;
        color: darken($gray-color-2,50%);
        margin-right: 0;
        visibility: hidden;
        @media (max-width: 1024px) {
          visibility: visible;
        }
      }
    }
  }

  .metrics, .summary, .segments {
    display: flex;

    .col {
      text-align: center;
      border-right: 1px $gray-color-1 solid;
      margin: 0;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        color: $text-color;
      }

      .value {
        color: $secondary-color;
      }

      .value.top-location {
        font-size: 15px;
        line-height: 20px;
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        // Addition lines for 2 line or multiline ellipsis
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;

        @media (max-width: 1024px) {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }

    .col.featured {
      margin: 0;
      background: $secondary-color;

      .value {
        color: $white-color;
        font-weight: 500;

        span {
          font-size: 18px;
          opacity: 0.6;
        }
      }
    }
  }

  .metrics, .summary {
    .col:last-child, .col:first-child {
      border: 0;
    }
  }

  .metrics {
    height: 92px;

    .title {
      font-size: 10px;

      @media (max-width: 1024px) {
        font-size: 11px;
      }
    }

    .value {
      font-size: 20px;
    }

    .col.featured {
      border-bottom-left-radius: 2px;
      cursor: auto;

      .value {
        font-size: 26px;
        line-height: 28px;
      }
    }
  }

  .summary {
    height: 160px;

    .title {
      font-size: 13px;
      font-weight: 300;
    }

    .value {
      font-size: 25px;
      line-height: 35px;
      @media (max-width: 1024px) {
        font-size: 22px;
        line-height: 32px;
      }
    }

    .col {
      margin: 4rem 0;
      display: flex;
      flex-direction: column;

      .summary-icon {
        margin-top: 1rem;
        margin-bottom: 4rem;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
           width: 38px;
           &.top-gender {
             height: 34px;
             width: auto;
           }
         }
      }
    }

    .col.featured {
      // border-top-left-radius: 2px;
      // border-bottom-left-radius: 2px;
      cursor: default; //Remove later

      .value {
        font-size: 32px;

        @media (max-width: 1024px) {
          font-size: 30px;
        }
      }
    }
  }
}

.content-widget.summary {
  margin-bottom: 12rem;

  .segment-block {
    position: relative;
    flex: 4;
    display: flex;
    flex-direction: column;

    .segment-name {
      padding: 1rem 3rem;
      margin: 0;
      border: 0;
      border-bottom: 1px $gray-color-1 solid;
    }

    .segment-values {
      display: flex;
      flex: 1;
    }
  }
}

.content-widget.summary.disabled {
  pointer-events: none;
  opacity: .4;
}

.content-widget.link:not(.link-paid).add-new {
  margin-bottom: 19.2rem;
}

.content-widget.link.link-paid {

  .alias {
    .total-responses {
      display: inline;
      font-size: 20px;
      font-weight: 400;
      vertical-align: bottom;
      //margin-bottom: 0;
    }

    input {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .metrics {

    .col {
      cursor: initial;
    }

    .col.featured {
      background: $featured-color;

      .title {
        color: rgba($white-color, .6);
      }
    }
  }
}

.content-widget.link.add-new,
.content-widget.link.link-paid.add-new,
.content-widget.defineAudience.add-segment {
  position: relative;
  border-style: dashed;
  border-width: 2px;

  .alias, .link, .metrics, .segments {
    filter: grayscale(100%);
    opacity: 0.35;
  }

  .col.featured {
    background: $gray-color-2;
  }

  .add-new-label {
    position: absolute;
    text-align: center;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .icon-wrapper {
      margin: auto;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      line-height: 50px;
      margin-bottom: 4rem;

      i {
        color: $white-color;
        font-size: 30px;
      }
    }

    .label {
      font-size: 22px;
      font-weight: 400;
    }
  }
}

.content-widget.link.add-new,
.content-widget.defineAudience.add-segment {
  border-color: $main-color;

  .icon-wrapper {
    background: $main-color;
  }

  .label {
    color: $main-color;
  }
}

.content-widget.link.link-paid.add-new {
  border-color: $featured-color;

  .icon-wrapper {
    background: $featured-color;
  }

  .label {
    color: $featured-color;
  }
}

.content-widget.link.add-new:hover,
.content-widget.link.add-new input:hover,
.content-widget.defineAudience.add-segment:hover {
  cursor: pointer;
}


.widget-title {
  font-size: 18px;
  font-weight: 400;
  color: #7f7d86;
  margin: 0 0 4rem;
  padding-bottom: 4rem;
  border-bottom: 1px #33333C solid;

  .button-info {
    display: inline-block;
    margin-left: 1rem;
  }

  .actions {
    float: right;
    display: flex;

    span {
      margin-left: 1rem;
    }

    span:hover {
      cursor: pointer;
    }

    .btn-cancel {
      padding: 1rem 2rem 0.8rem 1rem;
      border-radius: 6px;
      border: 1px rgb(127, 125, 134) solid;
      position: relative;
      top: -5px;
      /* background: #7f7d86; */
      color: rgb(127, 125, 134);
      height: 22px;
      display: flex;
      margin-right: 6px;

      p {
        color: rgb(127, 125, 134);
        /* color: #000000; */
        margin: 0;
        font-size: 13px;
      }

      i {
        font-size: 16px;
        margin-right: 1rem;
      }
    }
  }

  .actions.disabled {
    .rewind,
    .play,
    .stop,
    .muted {
      opacity: 0.4;
    }
  }
}

.left-content {
  flex: 1 1;
  display: flex;
  align-items: center;

  .breadcrumb {
    color: #717171;
    margin-bottom: 2rem;
  }
}

.left-content .search {
  margin-bottom: 0;
  margin-left: 5rem;
  width: 300px;
}

.left-content .search .input-icon {
  background: #262730;
}

.left-content .search .input-icon i {
  font-size: 18px;
}

.left-content .search input {
  padding-left: 0;
}

.right-content {
  display: flex;
  text-align: right;
}

.center-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.user-info {
  display: flex;
  align-items: center;
  padding-right: 10rem;
}

.user-info a {
  display: flex;
  text-decoration: none;
}

.avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  /* border: 2px #6B6971 solid; */
  border: 2px #6B6971 solid;
}


.avatar img {
  width: 100%;
}

.name-and-company {
  font-size: 13px;
  font-weight: 400;
  color: #7f7d86;
  margin-left: 3rem;
  text-align: left;

  .subscription-type {
    text-transform: uppercase;
    font-size: 13px;
    color: #EDEDF1;
    font-weight: 600;
    margin-bottom: 4px;
  }

  b {
    color: #EDEDF1;
    font-weight: 500;
  }

  /*
  span {
    text-transform: capitalize;
    font-size: 12px;
    color: #7f7d86;
    display: block;
    margin-top: 1rem;
  }
  */

  /*
  span.premium {
    color: #F08C1E;
  }
  */
}

.suscription-info {
  border: 2px #6B6971 solid;
  border-radius: 23px;
  height: 46px;
  color: #6B6971;
  padding: 0 2rem 0 1rem;
  box-sizing: border-box;
  margin-left: 2rem;
  display: flex;

  .suscription-info-icon {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    line-height: initial;
  }

  .suscription-info-item {
    display: flex;
    line-height: initial;
    height: 100%;
    margin: 0 2rem;

    img {
      width: 18px;
      height: auto;
    }
  }

  .suscription-info-text {
    margin-top: 4px;

    p {
      text-align: left;
      margin: 0;
      font-size: 14px;

      .current {
        font-weight: 500;
        color: #EDEDF1;
      }
    }

    .progress-bar {
      margin-top: 4px;
      height: 4px;
      width: 100%;
      background: #FFFFFF30;

      .progress {
        min-width: 1%;
        max-width: 100%;
        height: 100%;
        background: #278DF3;
      }
    }
  }
}

// .user-info.have-subscription
/*
.user-info {
  .subscription-type {
    color: #F08C1E;
  }

  .avatar {
    border: 2px #F08C1E solid;
  }
}
*/

.subscription {
  background: #F08C1E;
  width: 34px;
  height: 34px;
  border-radius: 8px;
  margin-left: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
}

.subscription i {
  color: #EDEDF1;
  font-size: 20px;
  width: 100%;
}

.subscription-widget {
  border: 1px #33333c solid;
  border-radius: 12px;
  padding: 3rem 4rem;
  color: #7f7d86;

  .suscription-info {
    margin-left: 0;
    margin-top: 4rem;
    margin-bottom: 2rem;
    flex-direction: column;
    border: 0;
    padding: 0;
    height: auto;
    border-top: 1px #33333C solid;
    padding-top: 3rem;
    border-radius: 0;

    .suscription-info-item {
      margin: 0;
      margin-bottom: 2rem;
    }

    .suscription-info-text {
      width: 100%;
      font-weight: 500;
    }

    .current {
      margin-left: 2rem;
    }
  }


  .subscription-row {
    display: flex;
    margin-bottom: 0rem;
  }

  .subscription-title {
    color: #F08C1E;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    flex: 1;
  }

  button.inline {
    color: #7f7d86;
    margin-top: -2rem
  }

  .subscription-details {
    font-size: 15px;
    margin-bottom: 3rem;

    b {
      color: #F08C1E;
      font-weight: 500;
    }
  }

  .subscription-renovation {
    font-size: 12px;
  }

  .subscription-renovation.cancelled {
    color: $error-color;

    i {
      color: $error-color;
      font-size: 14px;
    }
  }

  .subscription-status {
    font-size: 15px;
    border-top: 1px #33333c solid;
    padding-top: 3rem;
    margin-top: 4rem;

    b {
      color: #EDEDF1;
      font-weight: 500;
      /* font-size: 18px; */
    }

    small {
      font-size: 12px;
      margin-top: 2rem;
      display: inline-block;
    }
  }
}

/*
.subscription-widget.free {
  border: 1px #EDEDF1 solid;

  .subscription-title {
    color: #EDEDF1;
  }

  .subscription-details b {
    color: #EDEDF1;
  }
}
*/

body .subscriptions-modal-root .rc-dialog-content {
  max-width: 1260px;
  min-width: 1124px;
  width: calc(100% - 12rem);
}

.subscriptions-modal {
  margin-top: 12rem;
  text-align: center;


  .subscriptions-modal-subtitle p {
    font-size: 15px;
    font-weight: 500;
    color: $secondary-color;
    margin: 0;
    margin-bottom: 2rem;
  }

  .subscription-plans {
    display: flex;
    margin-top: 12rem;
    margin-left: -3rem;
    margin-right: -3rem;
    margin-bottom: 6rem;

    .col {
      margin: 0 2rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;

      .push-down {
        flex: 1;
      }
    }

    .col.compact {
      width: 200px;
      flex: initial;
    }

    .col.custom {
      .plan-description {
        margin: 4rem 0 6rem;
      }

      button {
        margin-bottom: 6rem;
      }
    }

    .col.free {
      .plan-pay-wrapper {
        min-height: 0px;
        margin-bottom: 32px;
        margin-top: 4rem;
      }
    }

    .col.recommended {
      .plan-box {
        margin-top: -4px;
        border: 4px $main-color solid;
      }
    }

    .col.active .plan-box {
      border: 1px $featured-color solid;
    }

    .plan-recommended-text {
      font-size: 10px;
      font-weight: 600;
      color:$main-color;
      position: absolute;
      top: -7rem;
      left: 50%;
      transform: translateX(-50%);
    }

    .plan-more-info-wrapper {
      display: block;
      height: 22px;
      margin-top: 3rem;

      a {
        color: #6B6971;
        font-size: 12px;
      }

      i {
        color: #6B6971;
        font-size: 15px;
      }
    }

    .plan-box {
      border: 1px $gray-color-2 solid;
      border-radius: 2px;
      padding: 0 4rem;
      font-weight: 400;

      .plan-active-info {
        font-size: 12px;
        color: $featured-color;
      }

      .plan-title {
        font-size: 17px;
        font-weight: 500;
        text-transform: uppercase;
        color: #6B6971;
        margin-top: 6rem;
      }

      .plan-price-wrapper {
        display: inline-block;
      }

      .plan-price {
        display: flex;
        margin: 6rem auto 4rem;

        .plan-price-currency{
          font-size: 19px;
          color: $secondary-color;
          position: relative;
          top: 2rem;
        }

        .plan-price-value{
          font-size: 40px;
          font-size: 36px;
          color: $secondary-color;
          margin: 0 1rem 0 0.8rem;
        }

        .plan-price-month{
          font-size: 14px;
          display: flex;
          flex-direction: column;
          text-align: left;
          margin-top: 2rem;
          line-height: 16px;

          span:not(.decimals) {
            margin-left: 2px;
          }
        }
      }

      .plan-description {
        font-size: 13px;
        line-height: initial;
        color: #6B6971;
      }

      .button {
        width: 100%;

        span {
          margin: auto;
        }
      }

      .plan-pay-wrapper {
        min-height: 86px;
        display: flex;
        align-items: center;

        .plan-pay {
          width: 100%;
        }

        .field {
          text-align: center;
          margin-bottom: 0rem;

          .small-select {
            position: relative;
            top: initial;
            right: initial;
          }

          select {
            padding-left: 0;
          }
        }
      }

      .plan-characteristics {
        margin-bottom: 6rem;

        p {
          margin: 1rem 0;
        }
      }
    }
  }
}

.subscription-period-selector-wrapper  {
  margin-top: 6rem;
  display: flex;

  .subscription-period-selector{
    margin: auto;
    display: flex;
    border-radius: 2px;
    overflow: hidden;
    border: 1px $main-color solid;

    .selector-button {
      font-size: 14px;
      padding: 3rem 4rem;
      /* background: #14151C; */
      /* border: 1px #14151C solid; */
      /* border-radius: 6px; */
    }

    .selector-button:hover {
      cursor: pointer;
    }

    .selector-button.active {
      background: $main-color;
      /* border: 1px $main-color solid; */
      color: #ffffff;
    }

    .discount {
      color: #EDEDF1;
      background: $main-color;
      border-radius: 13px;
      padding: 1rem 2rem;
      margin-left: 1rem;
      font-size: 12px;
    }
  }
}

.test-item-options {
  position: absolute;
  background: $white-color;
  border: 1px $gray-color-2 solid;
  color: $text-color;
  z-index: 4;
  right: -14px;
  top: 7rem;
  text-align: left;
  width: 200px;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    transform: rotate(45deg);
    right: 27px;
    top: -6px;
    border-left: 6px $gray-color-2 solid;
    border-top: 6px $gray-color-2 solid;
    border-right: 6px $white-color solid;
    border-bottom: 6px $white-color solid;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    transform: rotate(45deg);
    right: 28px;
    top: -5px;
    border-left: 5px $white-color solid;
    border-top: 5px $white-color solid;
    border-right: 5px $white-color solid;
    border-bottom: 5px $white-color solid;
    z-index: -1;
  }

  button {
    width: 100%;
    font-size: 12px;
    padding: 2.8rem 0;
    text-align: left;

    i {
      font-size: 12px;
      display: inline-block;
      width: 40px;
      text-align: center;
    }
  }

  button:hover {
    background: $gray-color-2;
  }
}

.hidden-trigger {
  background: transparent;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

table {
  text-align: left;
  width: 100%;
  padding: 0;

  div {
    margin: 0;
    z-index: 2;
    position: relative;
  }

  th {
    color: $white-color;
    font-size: 12px;
    font-weight: 500;
    padding: 4rem;
    background: $secondary-color;
  }

  td:not(.actions) button.inline {
    color: $secondary-color;
    font-size: 12px;
    font-weight: 500;
    padding: 0;
  }

  td {
    color: $text-color;
    font-size: 12px;
    font-weight: 500;
    padding: 0 4rem;
    height: 50px;
    position: relative;
    border-bottom: 1px $gray-color-2 solid;
  }

  th.select {
    width: 20px;
  }

  td.actions {
    text-align: right;
    text-decoration: none;
    padding-top: 0rem;
    padding-bottom: 0rem;

    button:disabled {
      pointer-events: none;
    }
  }

  td.actions i {
    color: $text-color;
  }

  td .check .checkmark {
    border: 1px #EDEDF1 solid;
  }

  td .status-tag {
    background: $secondary-color;
    color: $white-color;
    font-size: 12px;
    padding: 1rem 2rem;
    font-weight: 500;
    text-transform: capitalize;
  }

  td .status-tag.paid,
  td .status-tag.inreview,
  td .status-tag.processing {
    background: #f08c1e;
  }

  td .status-tag.active,
  td .status-tag.public {
    background: $main-color;
  }

  td .status-tag.finished {
    background: #85BF3F;
  }

  td.interactions {
    color: $text-color;
    font-weight: 600;
  }

  tr.alt {
    // background: $gray-color-1;
  }

  tr.selected, tr:hover {
    background: $gray-color-1;
  }
}

.no-padding {
  padding: 0;
}

.hide {
  display: none;
}

.error-color {
  color: $error-color !important;
}

.featured-color {
  color: $featured-color;
}

.color-picker {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.color-picker-popover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.field.show-picker {

}

.button-upload-image {
  position: absolute;
  display: inline-block;
  background: $main-color;
  color: $white-color;
  border-radius: 0 2px 2px 0;
  font-size: 12px;
  line-height: 32px;
  text-align: right;
  text-decoration: none;
  padding: 0 3rem;
  top: 0;
  right: 0;
  bottom: 0;

  .rai-activity-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:not(.loading) .rai-activity-indicator {
    display: none !important;
  }

  &.loading {
    color: $main-color;
  }
}

.button-upload-image:hover {
  color: #ffffff;
  cursor: pointer;
}

.field.upload input {
  color: #7f7d86;
  padding-right: 100px;
}

.field .small-select {
  width: auto;
  display: inline-block;
  position: absolute;
  top: -2rem;
  right: 4rem;
}


.field .small-select select {
  background: transparent;
  font-size: 12px;
  padding-right: 20px;
  line-height: 36px;
  border: 0;
}

.field .small-select:after {
  top: 1px;
  width: auto;
}

.display-flex {
  display: flex;
}

.flex {
  flex: 1;
}

.flex.vertical-center {
  align-items: center;
  display: flex;
}

.simplebar-track {
  z-index: 2;
  bottom: 8px;
  right: 6px;
}

.simplebar-track.simplebar-vertical {
  width: 8px;
}

.simplebar-track.simplebar-horizontal {
  height: 8px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  top: 0;
  height: 8px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 1rem;
  bottom: 3rem;
  opacity: 1;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  left: 8px;
  right: 3rem;
  opacity: 1;
}

.simplebar-scrollbar:before {
  left: 0px;
  right: 0px;
  /* background: linear-gradient(-131deg, rgb(231, 176, 43) 0%, rgb(193, 62, 81) 100%); */
  background: #464750;
}

.simplebar-content {
  height: 100%;
}

.screen-content.modules .screen-content-inner,
.screen-content.preview .screen-content-inner {
  padding: 0;
  position: fixed;
  top: 182px;
  left: 51px;
  left: 352px;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  overflow: hidden;

  iframe,
  object {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.screen-content.preview {
  .section-modules {
    left: initial;
    right: 0px;
    border-right: 0;
    border-left: 1px $gray-color-2 solid;
  }

  .screen-content-inner {
    left: 0px;
    right: 301px;
  }
}

.flow-canvas-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.flow-canvas {
  position: relative;
  height: 100%;
  background: $background-color;
  background-image: linear-gradient(135deg, #d8d8d8 5.56%, #EDEEEE 5.56%, #EDEEEE 50%, #d8d8d8 50%, #d8d8d8 55.56%, #EDEEEE 55.56%, #EDEEEE 100%);
  background-size: 11px 11px;
  /* padding: 0 30rem; */
}

.flow-canvas .background-pattern {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('../assets/img/background-pattern.png');
  opacity: 0.06;
  pointer-events: none;
}

.flow-canvas.grabbable:hover {
  cursor: grab;
}

.flow-canvas.grabbable:active {
  cursor: grabbing;
}

.screen-card:not(.unsortable):hover {
  /* cursor: grab; */
  cursor: pointer;
}

.screen-card:not(.unsortable):active {
  cursor: grabbing;
}

.screen-card.unsortable.film.core {
  .card-header i {
    display: none;
  }
}

.screen-cards {
  position: absolute;
  display: flex;
  /* top: 50%; */
  top: 46%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 6rem));
  padding: 0 20rem;
}

.screen-cards ul,
.screen-modules ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.screen-card,
.screen-cards .placeholder {
  position: relative;
  width: 135px;
  border-radius: 8px;
  font-size: 12px;
  color: $text-color;
  text-align: center;
  background: $white-color;
  margin: 0 5rem;
  box-sizing: border-box;
  /* border-top: 2px #0f0f18 solid; */

  .editing-tag,
  .hover-tag {
    display: none;
  }

  .error-tag {
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    left: 50%;
    text-align: center;
    bottom: -10rem;
    display: flex;
    align-items: center;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}

.screen-card.active, .screen-card.have-errors {
  height: 194px;

  .card-header {
    padding: 0 calc(3rem - 2px);
    height: 32px;
    line-height: 30px;

    i {
      margin-top: -2px;
      margin-left: -12px;
      margin-right: 7px;
    }
  }

  .card-body {
    border-radius: 0 0 8px 8px;
    padding: 3rem 0 3rem;
  }
}

.screen-card.active {
  .card-header {
    border-left: 2px $main-color solid;
    border-top: 2px $main-color solid;
    border-right: 2px $main-color solid;
  }

  .card-body {
    border-left: 2px $main-color solid;
    border-right: 2px $main-color solid;
    border-bottom: 2px $main-color solid;
  }

  .editing-tag {
    font-size: 12px;
    color: $main-color;
    font-weight: 400;
    position: absolute;
    left: 50%;
    text-align: center;
    bottom: -10rem;
    display: flex;
    align-items: center;
    transform: translateX(-50%);
    white-space: nowrap;

    i {
      font-size: 14px;
      color: $main-color;
      margin-right: 1rem;
    }
  }

  .error-tag {
    display: none;
  }
}

.screen-card.have-errors {
  .card-header {
    border-left: 2px $error-color solid;
    border-top: 2px $error-color solid;
    border-right: 2px $error-color solid;
  }

  .card-body {
    border-left: 2px $error-color solid;
    border-right: 2px $error-color solid;
    border-bottom: 2px $error-color solid;
  }

  .editing-tag, .editing-tag i {
    color: $error-color;
  }
}

.screen-card.disabled {
  opacity: .6;
  :hover {
    cursor: initial;
  }
}

.screen-content-inner:not(.dragging-module) {
  .screen-card.have-options:not(.active):not(:active):hover,
  .screen-card.have-options.have-errors:not(.active):not(:active):hover  {
    height: 194px;

    .card-header {
      padding: 0 calc(3rem - 2px);
      height: 32px;
      line-height: 30px;

      i {
        margin-top: -2px;
        margin-left: -12px;
        margin-right: 7px;
      }
    }

    .card-body {
      border-radius: 0 0 8px 8px;
      padding: 3rem 0 3rem;
    }

    .hover-tag {
      font-size: 12px;
      font-weight: 400;
      position: absolute;
      left: 50%;
      text-align: center;
      bottom: -10rem;
      display: flex;
      align-items: center;
      transform: translateX(-50%);
      white-space: nowrap;

      i {
        font-size: 14px;
        margin-right: 1rem;
      }
    }
  }

  .screen-card.have-options:not(.active):not(:active):hover {
    .card-header {
      border-left: 2px $main-color solid;
      border-top: 2px $main-color solid;
      border-right: 2px $main-color solid;
    }

    .card-body {
      border-left: 2px $main-color solid;
      border-right: 2px $main-color solid;
      border-bottom: 2px $main-color solid;
    }

    .hover-tag {
      color: $main-color;

      i {
        color: $main-color;
      }
    }
  }

  .screen-card.have-options.have-errors:not(.active):not(:active):hover {
    .card-header {
      border-left: 2px $error-color solid;
      border-top: 2px $error-color solid;
      border-right: 2px $error-color solid;
    }

    .card-body {
      border-left: 2px $error-color solid;
      border-right: 2px $error-color solid;
      border-bottom: 2px $error-color solid;
    }

    .hover-tag {
      color: $error-color;

      i {
        color: $error-color;
      }
    }

    .error-tag {
      display: none;
    }
  }
}

.screen-card:not(.core) {
  .card-header:hover {
    cursor: move;
  }

  .card-header i {
    opacity: 0.5;
    color: #fff;
    transform: rotate(90deg);
  }
}

.screen-card:not(.core):not(.have-errors) {
  .card-header i {
    margin-left: -2rem;
    margin-right: 1rem;
  }
}

.screen-card.have-options:hover {
  cursor: pointer;
}

.screen-cards .screen-card:not(.placeholder) {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.10);
}

.screen-cards .placeholder {
  background: transparent;
  border: 2px $main-color dashed;
}

.screen-cards .placeholder.empty-card {
  border: 2px $main-color dashed;
}

.screen-card .card-header {
  line-height: 34px;
  background: transparent;
  background: $secondary-color;
  height: 34px;
  text-align: left;
  padding: 0 3rem;
  font-weight: 400;
  border-radius: 8px 8px 0 0;
  display: flex;
  color: $white-color;

  .card-header-text {
    flex: 1;
  }

  i {
    opacity: 0.4;
  }
}

/*
.screen-card.have-options .card-header {
  background: #0F1018;
}
*/

.screen-card:not(.placeholder):not(.have-options) {
  background: #E6E7E7;

  .card-header,
  .card-body {
    opacity: 0.4;
  }
}

.screen-card .card-body {
  padding: 3rem 0;
  pointer-events: none;
  background: $white-color;
  border-radius: 0 0 8px 8px;
}

.screen-cards .screen-card:not(.ui-sortable-helper):before,
.screen-cards .placeholder:before {
  content: '';
  width: 56px;
  height: 82px;
  position: absolute;
  right: -53px;
  background: url('../assets/img/card-union.png');
  background-size: 56px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.screen-content-inner .screen-cards .placeholder:before {
  right: -56px;
}

/* .screen-cards ul.last li:before, */
.screen-content-inner.cards-empty:not(.show-add-card) ul.last li:before,
.sortable-chosen:before {
  content: none !important;
}

.screen-cards ul.last li:before,
#sortable li.empty-card {
  right: auto;
  left: -52px;
}

.screen-cards ul.last li.empty-card:before {
  left: -54px;
  display: none;
}

/*
.screen-cards .last li.empty-card:before {
  content: none;
}
*/

.screen-cards .placeholder div {
  display: none;
}

#sortable .sortable-chosen + .screen-card:after {
  content: '';
  width: 56px;
  height: 82px;
  position: absolute;
  left: -53px;
  background: url('../assets/img/card-union.png');
  background-size: 56px;
  top: 50%;
  transform: translateY(-50%);
}

.screen-cards .empty-card {
  display: none;
}

.screen-cards .empty-card:hover {
  // cursor: pointer;
}

.screen-cards .empty-card i {
  display: none;
  color: $main-color;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.screen-card.audio .card-header {
  background: #78D1C4;
  color: #ffffffdd;
}

.screen-card.video .card-header {
  background: #E6565A;
  color: #ffffffdd;
}

.screen-card.image .card-header {
  background: #9a68da;
  color: #ffffffdd;
}

.screen-card.qa .card-header {
  background: #FF9800;
  color: #ffffffdd;
}

#scrollview {
  transition: width 0.3s ease-in-out;
}

.screen-modules {
  /* margin-top: 6rem; */

  #sortable-b li {
    margin: 0;
    background: transparent;
    border-bottom: 1px $gray-color-2 solid;
  }

  .module {
    background: $background-color;
    border-radius: 0;
    height: 70px;
    padding: 0 6rem;
    color: $secondary-color;
    display: flex;
    align-items: center;
    /* margin-bottom: 4rem; */

    h4 {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0;
      flex: 1;
      margin-left: 3rem;
      text-align: left;
    }

    .icon-plus-1 {
      color: $secondary-color;
      font-size: 20px;
    }

    .module-type-icon {
      font-size: 20px;
      position: relative;
      top: -3px;
      color: $secondary-color;
    }
  }
}

#sortable-b {
  padding: 0;
  position: inherit;
  transform: none;
  flex-direction: column;
}

#sortable-b li {
  margin: 0;
  margin-bottom: 4rem;
}

#sortable-b .screen-card {
  width: 100%;
}

#sortable-b .card-header,
#sortable-b .card-body {
  display: none;
}

#sortable .module {
  display: none;
}
/*
#sortable.empty .sortable-chosen {
  display: none;
}
*/

.empty.placeholder {
  display: none;
}

.cards-empty #sortable .sortable-chosen {
  display: none;
}

.screen-content-inner.show-empty-card,
.screen-content-inner.cards-empty {
  .empty.placeholder {
    display: block;
  }
}

.show-add-card {
  ul:not(.last) .empty.placeholder {
    display: none;
  }

  ul.last .empty-card {
    display: block;
  }
}

/*
#sortable {
  .sortable-chosen ~ .empty-card {
    display: none;
  }
}
*/

.notification {
  background: $error-color;

  p {
    color: $white-color;
    font-size: 12px;
  }
}

.notification.success {
  background: $success-color;
}

.notification.information {
  background: $featured-color;
}

#root {
  height: 100%;
}

.loading-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 9;

  div {
    margin: auto;
  }

  .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $secondary-color;
  }
}

.loading-cover .cover.design {
  background: $background-color;
  background-image: linear-gradient(135deg, #d8d8d8 5.56%, #EDEEEE 5.56%, #EDEEEE 50%, #d8d8d8 50%, #d8d8d8 55.56%, #EDEEEE 55.56%, #EDEEEE 100%);
  background-size: 11px 11px;
  /* padding: 0 30rem; */
}

.loading-cover .cover.white {
  background: $white-color;
  opacity: .6;
}

.click-handler {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.add-module-float-button {
  width: 74px;
  height: 74px;
  box-sizing: border-box;
  border-radius: 37px;
  box-shadow: 0px 2px 20px #00000052;
  position: fixed;
  bottom: 10rem;
  right: 10rem;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.3s ease-in-out;
  transition-delay: 0.2s;

  i {
    font-size: 30px;
    margin: 0;
    line-height: 74px;
  }
}

.sortable-deleted {
  position: absolute;
  border-radius: 44px;
  margin: 0;
  padding: 0;
  border: 2px #6D6D78 dashed;
  z-index: 9;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 3rem;
  bottom: 6rem;
  left: 6rem;
  min-width: 82px;
  min-height: 82px;
  box-sizing: border-box;
  transition: border 0.4s ease-in-out;

  i {
    font-size: 20px;
    color: #6D6D78;
    text-align: center;
    margin-left: 2rem;
    transition: color 0.4s ease-in-out;
  }

  p {
    color: #6D6D78;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    max-width: 0px;
    transition: max-width 0.4s ease-in-out, margin-right 0s ease-in-out, margin-left 0s ease-in-out, color 0.4s ease-in-out;
    transition-delay: 0s, 0.4s, 0.4s, 0s;
  }

  ul {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .screen-card {
    display: none;
  }
}

.dragging-module {
  .sortable-deleted {
    border: 2px $error-color dashed;

    p {
      max-width: 600px;
      margin-left: 1rem;
      margin-right: 2rem;
      transition-delay: 0s, 0s, 0s, 0s;
    }

    p, i {
      color: $error-color;
    }
  }
}

/*
.sortable-deleted:hover {
  cursor: help;

  p {
    max-width: 600px;
    margin-left: 1rem;
    margin-right: 2rem;
    transition-delay: 0s, 0s, 0s, 0s;
  }
}
*/

.col.phone {
  max-width: 260px;
}

.app .scrollbar-track-y {
  .scrollbar-thumb {
    background: transparent;
  }

  .scrollbar-thumb:before {
    content: '';
    background: #464750;
    top: 8px;
    right: 0;
    bottom: 10px;
    left: 0;
    position: absolute;
    border-radius: 4px;
  }
}

.hidden {
  visibility: hidden;
}

.no-campaigns-msg {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;

  @media (max-width: 1024px) {
    button {
      padding: 0 3rem;
    }
  }

  img {
    width: 50px;
  }

  h2 {
    margin-top: 7rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    margin-top: 0;
  }

  button {
    margin-top: 2rem;
  }
}

.no-results-msg-wrapper {
  position: fixed;
  top: 182px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.no-results-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;

  &>i {
    font-size: 30px;
    line-height: 50px;
  }

  img {
    width: 50px;
    margin-bottom: 10rem;
  }

  h2 {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 4rem;
  }

  button {
    margin-bottom: 3rem;
  }

  .button-text {
    display: flex;
    align-items: center;

    i {
      margin-left: 1rem;
    }
  }
}

.content-widget.main {
  background: #278DF4;
  color: #EDEDF1;

  .widget-title {
    color: #EDEDF1;
    border-color: #ffffff50;
  }

  .button {
    font-weight: 600;
    color: #278DF4;
    background: #EDEDF1;

    i {
      color: #278DF4;
    }
  }

  .field label {
    color: #EDEDF1;
  }
}

.campaign-resume {
  display: flex;
  color: #EDEDF1;

  div {
    flex: 1;
  }

  span {
    font-size: 22px;
    font-weight: 600;
    display: block;
  }
}

.promo-code-input {
  border-top: 1px #33333C solid;
  margin-top: 6rem;
  padding-top: 4rem;
  display: flex;

  .field.discount-code {
    flex: 1;
    margin-bottom: 0;
  }

  .field.last {
    margin-top: 7rem;
  }
}

.user-credit-input {
  border-top: 1px #33333C solid;
  margin-top: 6rem;
  padding-top: 4rem;

  .field.discount-code {
    flex: 1;
    margin-bottom: 0;
  }

  .field.last {
    margin-top: 7rem;
  }

  p:not(.last) {
    margin-bottom: 2rem;
  }

  span.large {
    font-weight: 500;
    font-size: 15px;
  }

  span.large {
    /* font-size: 18px; */
  }
}

.publish-plan {
  border-top: 1px #33333C solid;
  margin-top: 6rem;
  padding-top: 4rem;

  .field {
    flex: 1;
    margin-bottom: 0;
  }

  .content-widget {
    padding: 0;
    margin-bottom: 0;
  }

  .widget-title {
    display: none;
  }
}

.plan-info {
  color: #EDEDF1;
  margin-top: 3rem;
  font-size: 12px;
  margin-bottom: 6rem;

  button.inline {
    color: #717171;
    margin-left: 1rem;
  }
}

.plan-info.info,
.plan-info.info i {
  color: #F08C1E;
}

.plan-info.ok,
.plan-info.ok i {
  color: #85BF3F;
}

.plan-info.ko,
.plan-info.ko i {
  color: $error-color;
}

.flex-push {
  flex: 1;
}

.billing {
  border-top: 1px #33333C solid;
  margin-top: 6rem;
  padding-top: 4rem;
  text-align: right;
  color: #EDEDF1;
  margin-bottom: 6rem;

  .billing-resume {
    margin-bottom: 5rem;
    font-size: 15px;

    .billing-send-flex {
      /* display: flex; */
    }

    .billing-sends {
      color: #717171;
      margin-bottom: 4rem;

      .price {
        font-size: 20px;
        font-weight: 500;
        margin-left: 4rem;
        color: #717171;
        /* width: 86px; */
        /* text-align: left; */
      }

      .price.included {
        text-decoration: line-through;
      }

      .billing-small-legend {
        /* display: inline-block; */
        font-size: 11px;
      }
    }

    .billing-sends-resume {
      font-size: 18px;
    }

    .billing-sends.included {
      .billing-small-legend,
      .price {
        color: #F08C1E;
      }
    }

    .billing-sends.non-included {
      .billing-small-legend,
      .price {
        color: #EDEDF1;
      }
    }
  }

  .free-cost-title {
    display: inline-block;
    font-size: 14px;
    color: #FF9800;
    margin-right: 2rem;
  }

  .free-cost-message {
    display: block;
    font-size: 13px;
    color: #FF9800;
    margin-bottom: 1rem;
  }

  .free-cost-message.free {
    color: #85BF3F;
  }

  p {
    margin-bottom: 0;
    color: #EDEDF1;
  }

  span.price {
    font-size: 32px;
    font-weight: 400;
    margin-left: 3rem;
    position: relative;
    color: #EDEDF1;
  }

  .cost.discount {
    margin-top: 2rem;
  }

  .discount {
    color: #85BF3F;
  }

  /*
  span.free:before {
    content: '';
    position: absolute;
    height: 3px;
    background: $error-color;
    top: 49%;
    right: -6px;
    left: -6px;
    transform: rotate(-8deg);
  }
  */
}



.pay {
  .button {
    margin-top: 6rem;
    margin-left: 0;
  }
}

.content-widget.more-bottom {
  padding-bottom: 6rem;
}

.loading-modal {
  position: relative;
  min-height: 100px;
  background: #EDEEEE;
  
  .rai-activity-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}

.rc-dialog-root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;

  .recharged-credit-input {
    margin-top: 6rem;
  }

  .rc-dialog.loading:not(.invert-buttons) .main-button {
    span {
      visibility: hidden;
    }
  }

  .rc-dialog.loading.invert-buttons .secondary {
    span {
      visibility: hidden;
    }
  }

  .rc-dialog-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #6b8286;
    opacity: 0.8;
    z-index: 9;
  }

  .rc-dialog-mask-hidden {
    display: none;
  }

  .rc-dialog-content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    background: $white-color;
    border: 0px $gray-color-2 solid;
    height: auto;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    padding: 5rem;
    box-sizing: border-box;
    min-width: 500px;

    button {
      height: 42px;
      font-size: 13px;
    }
  }

  .rc-dialog.small-modal {
    .rc-dialog-content {
      max-width: 500px;
    }
  }

  .rc-dialog.dialogInfo {
    .rc-dialog-close {
      right: -5rem;
      top: -7rem;
      @media only screen and (max-width: 1024px) {
        right: 1rem;
        top: 0;
        i {
          color: $gray-color-2;
        }
      }
    }
    .rc-dialog-content {
      padding-bottom: 0;
      min-width: 600px;
      @media only screen and (max-width: 600px) {
        min-width: auto;
      }
    }
    .rc-dialog-body .modal-body {
      background: $white-color;
      border-top: 1px solid $gray-color-1;
      @media only screen and (max-width: 600px) {
        padding: 3rem;
      }
      @media only screen and (max-height: 800px) {
        max-height: 70vh;
        overflow-y: scroll;
      }
    }
  }
  
  .rc-dialog.dialogInfo.dialogInfoLarge {
    .rc-dialog-content {
      @media only screen and (min-width: 1024px) {
        min-width: 990px;
      }
    }
  }

  .rc-dialog-close {
    background: transparent;
    border: 0;
    position: absolute;
    right: -8rem;
    top: -7rem;

    i {
      color: $white-color;
      font-size: 16px;
    }
  }

  .rc-dialog-close:hover {
    cursor: pointer;
  }

  .rc-dialog-close:focus {
    outline: 0;
    border: 0;
  }

  .rc-dialog-title {
    color: $secondary-color;
    font-size: 24px;
    font-weight: 300;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }

  .rc-dialog-body {
    color: $secondary-color;

    .modal-subtitle {
      margin-top: -3rem;
      margin-bottom: 5rem;
      font-size: 13px;
      font-weight: 400;
    }

    .modal-body {
      background: $gray-color-1;
      margin-left: -5rem;
      margin-right: -5rem;
      padding: 5rem 5rem 5rem 5rem;
      max-height: 50vh;
      overflow-y: auto;

      p {
        /* margin: 0; */
        font-size: 13px;
      }
    }

    a {
      color: $main-color;
      text-decoration: none;
    }

    button.full-width {
      margin: 0;
    }

    .policy-block {
      margin-bottom: 2rem;

      p {
        margin: 0;
      }

      a {
        color: $secondary-color;
        font-weight: 500;
      }
    }

    h2 {
      font-weight: 500;
      font-size: 16px;
      margin-top: 5rem;
    }

    .large {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .dialog-buttons {
    text-align: right;
    margin-top: 4rem;
    /*
    margin-right: -2rem;
    margin-left: -2rem;
    margin-bottom: -2rem;
    */

    button {
      margin-left: 4rem;
      @media only screen and (max-width: 1024px) {
        line-height: inherit;
        padding: 0 3rem;
      }
    }

    .inline.button-red {
      color: $error-color;
    }
  }
}

.row.metrics {
  max-width: none;

  .col {
    flex: 1;
  }

  .push-col {
    width: 300px;
    width: 360px;
    flex: none;
  }

  .fixed {
    position: absolute;
    right: 10rem;

    video,
    img,
    audio {
      width: 100%;
      max-height: 200px;
      margin: auto;
      object-fit: contain;
    }
  }
}

.panel.metrics-filter {
  width: 340px;
  width: 400px;

  .flex {
    display: flex;
    flex-direction: column;
  }

  .fixed-top {
    /* height: 200px; */
    margin-top: -8rem;
    border-bottom: 1px $gray-color-2 solid;
    margin-bottom: 3rem;
    /* margin: 0 6rem 6rem; */
    /* padding: 6rem 0; */
    background: black;
  }

  video,
  img,
  audio {
    width: 100%;
    max-height: 200px;
    margin: auto;
    object-fit: contain;
  }

  audio {
    margin: 6rem;
    width: calc(100% - 12rem);
  }

  video:focus {
    outline: none;
  }

  .panel-title {
    color: #7f7d86;
  }

  .module-title {
    color: #7f7d86;
    padding: 2rem;
    margin: 4rem 6rem 2rem;
    border-radius: 6px;

    i {
      margin-right: 2rem;
    }

    .type-badged {
      margin-left: 4rem;
      padding: 0.6rem 2rem;
      border-radius: 6px;
      color: #EDEDF1;
    }

    .type-badged.video {
      background: #E6565A;
    }

    .type-badged.audio {
      background: #78D1C4;
    }

    .type-badged.image {
      background: #9a68da;
    }

    .type-badged.qa {
      background: #FF9800;
    }
  }

  .module-title:hover {
    cursor: pointer;
  }

  .module.active {
    .module-title {
      color: #EDEDF1;
      background: #262127;
    }

    ul {
      display: block;
    }
  }

  ul {
    display: none;
    list-style: none;
    color: #7f7d86;
    margin-top: 0;
    margin-bottom: 6rem;
    padding-left: 12rem;
    padding-right: 6rem;

    li {
      display: flex;
      margin-bottom: -2rem;

      .color {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin: 16px 3rem 0 0;
      }

      p {
        flex: 1;
        white-space: break-spaces;
      }

      i {
        color: #7f7d86;
        font-size: 15px;
      }

      button.inactive {
        opacity: 0.4;
      }
    }

    li.inactive {
      p,
      button {
        color: #7f7d86 !important;
        opacity: 0.4;
      }

      .color {
        border: 1px #7f7d86 solid;
        width: 10px;
        height: 10px;
        background: transparent !important;
        opacity: 0.4;
      }
    }
  }
}

.emotional-overview {
  margin-top: 4rem;
  height: 220px;

  // @media (max-width: 600px) {
  //   min-height: 300px;
  // }
}

.responses-obtained-modal {
  display:flex;
  justify-content: center;
  flex-wrap: wrap;

  .responses-chart {
    min-width: 470px;

    @media (max-width: 600px) {
      min-width: 330px;
    }
  }
}

.publish-test-box {
  padding: 6rem 0 7rem;
  margin-top: -12rem;
  margin-bottom: 12rem;
  background-color: $secondary-color;
  
  .publish-test-wrapper {
    display: flex;
    margin-bottom: 0;
    position: relative;

    .loading-cover {
      position: absolute;
      top: 0;

      .cover {
        opacity: 0.6;
      }
    }
  }

  .publish-test-image {
    width: 320px;
    text-align: center;

    img {
      width: 215px;
    }
  }

  .publish-test-content {
    flex: 1;

    h2 {
      font-size: 20px;
      font-weight: 300;
      color: $main-color;
    }

    p {
      font-size: 16px;
      color: $text-color;

      b {
        color: $white-color;
      }
    }
    
    h3 {
      color: $white-color;
      margin: 4rem 0 3rem;
    }

    p.small {
      font-size: 12px;
      margin: 3rem 0 0;
    }

    .publish-buttons {
      display: flex; 
      margin-top: 6rem;

      &.pay {
        display: block;
        text-align: right;
        flex-direction: column;
        // flex-direction: column;
        // margin-top: 0;

        .main-button {
          margin-top: 0;
        }

        .adquisiton-method-button { 
          margin-top: 0;
        }

        .total-price {
          font-size: 22px;
          /* margin-right: 4rem; */
          margin-bottom: 4rem;

          span {
            font-size: 28px;
            margin-left: 2rem;
            font-weight: 400;
          }
        }
      }

      .adquisiton-method-button {
        height: auto;
        padding: 2rem 4rem 2rem 2rem;
        text-transform: none;
        margin-right: 4rem;

        &:hover {
          cursor: pointer;
          border-color: $white-color;

          i {
            color: $white-color;
          }
        }
  
        .button-text {
          display: flex;
          text-align: left;
          line-height: normal;
  
          .icon {
            display: flex;
            align-items: center;
            margin-right: 1rem;
          }
  
          .text {
            .title {
              display: block;
              margin-bottom: 1rem;
            }
  
            .value {
              font-size: 15px;
              color: $white-color;
            }
          }
        }
      }
      
      .main-button {
        height: 59px;
        padding: 3px 6rem 0;
        font-size: 13px;

        &.disabled {
          display: none;
          // background: $main-color;
          // color: $white-color;
          pointer-events: none;
          cursor: default;
          opacity: 0.6;
        }
      }
    }

    .processing-warning {
      margin-top: 0rem;
      margin-bottom: 0rem;

      p {
        margin: 0;
        color: $featured-color;
      }
    }
  }
}

.right-column {
  width: 280px;
  margin-left: 6rem;

  button {
    height: 59px;
    width: 100%;
    font-size: 13px;
  }

  .separator { 
    margin: 4rem 0;
    border-color: $gray-color-1;
  }

  .total-price {
    display: flex;
    font-size: 28px;
    color: $secondary-color;

    .legend {
      flex: 1;
    }

    .value {
      text-align: right;
    }

    .sublegend {
      margin-top: 1rem;
      font-size: 11px;
    }
  }

  .details {
    display: flex;
    margin-bottom: 2rem;

    .legend {
      flex: 1;
      margin-left: 2rem;
    }
  }

  .responses-price {
    font-weight: 500;
    font-size: 15px;
    color: $secondary-color;

    &:not(:first-child) {
      margin-top: 3rem;
    }
    
    .legend {
      margin-left: 0;
    }
  }

  .content-widget {
    margin-top: 0;
    padding-bottom: 5rem;

    h3 {
      font-size: 20px;
      font-weight: 300;
      margin: 0 0 4rem;
      color: $secondary-color;
    }

    p {
      font-size: 15px;
    }

    &.contact-us {
      p {
        margin-bottom: 0;
      }

      .image {
        display: none;
        text-align: center;

        img {
          width: 210px;
          margin-top: -2rem;
        }
      }

      .button {
        margin-top: 6rem;
      }
    }
  }
}

.content-wrapper.pay {
  h2 {
    font-size: 20px;
    font-weight: 400;
    color: #1C1D2E;
    margin: 0;
    margin-bottom: 5rem;

    &:not(:first-child) {
      margin-top: 12rem;
    }
  }

  .link-back {
    margin-left: -1rem;
    color: $text-color;
  }

  .no-margin-top {
    margin-top: 6rem;
  }

  p {
    font-size: 16px;
  }

  .content-widget {
    margin-top: 6rem;
    margin-bottom: 6rem;
    padding-bottom: 5rem;
  }

  .number-of-responses-wrapper {
    h3 {
      margin: 0 0 3rem;
    }

    p.small {
      font-size: 11px;
      margin-bottom: 0;
    }

    input {
      border: 1px $gray-color-2 solid;
    }
  }

  .number-of-responses {
    flex: 1;
    height: auto;
    // padding: 2rem 4rem 2rem 2rem;
    text-transform: none;
    background: transparent;
    // color: #777782;
    // border: 1px #777782 solid;
    

    .title {
      font-size: 16px;
      color: $white-color;
      margin-bottom: 2rem;
    }

    .value {
      // align-items: center;
      display: flex;

      input {
        margin-right: 4rem;
        height: 46px;
        border-radius: 6px;
        width: 100px;
        text-align: center;
        font-size: 18px;
      }
    }

    .MuiSlider-valueLabelLabel {
      font-size: 12px;
    }
    
    .MuiSlider-root {
      font-size: 12px;
      color: $gray-color-2;
      height: 14px;
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 0;

      .MuiSlider-track {
        background-color: $main-color;
        border: 0;
      }

      .MuiSlider-mark {
        // display: none;
        height: 14px;
        width: 2px;
        color: $gray-color-2;
        opacity: 0.4;
      }

      .MuiSlider-mark[data-index="5"] {
        display: none;
      }
    
      .MuiSlider-markLabel {
        margin-top: -8px;
        font-size: 12px;
        color: $text-color;

        &:last-child {
          transform: translateX(-100%);
        }
      }

      .MuiSlider-markLabel[data-index="0"] {
        transform: translateX(0);
      }

      .MuiSlider-markLabel[data-index="5"] {
        transform: translateX(-100%);
      }
    }
  }

  .featured-clients {
    h3 {
      font-size: 20px;
      font-weight: 300;
      margin: 0 0 4rem;
    }

    .image {
      display: flex;
      width: 100%;
      margin: 0 0 4rem;
      text-align: center;

      img {
        flex: 1;
        width: 10%;
        margin: 0 1rem;

        &:not(.active) {
          opacity: 0.4;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    p {
      font-size: 13px;
    }

    .name-and-charge {
      font-weight: 400;
    }
  }

  .test-resume {
    .duration {
      margin-top: 0;
      font-size: 15px;
      font-weight: 400;
      color: $secondary-color;
      display: flex;
      justify-content: space-between;
    }

    .duration-disclaimer {
      font-size: 13px;
      font-weight: 300;
      margin-bottom: 0;
    }
  
    .modules-container {
      .separator {
        border-bottom: 1px solid $gray-color-1;
        margin: 0 0 3rem;
      }

      .modules {
        .module-title {
          border-bottom: 0px solid $gray-color-1;
          padding-bottom: 3rem;
          margin-bottom: 0rem;
          display: flex;
          align-items: center;

          .separator {
            margin: 0 5rem;
          }
  
          .title-tech {
            display: flex;
            align-items: center;
            flex: 1 1;
  
            h2 {
              font-size: 15px;
              flex: initial;
            }
  
            .tech {
              background-color: $gray-color-1;
              color: rgba($secondary-color, .6);
              font-weight: 400;
              font-size: 12px;
              text-transform: capitalize;
              padding: 0.3rem 1rem;
              margin-left: 3rem;
              border-radius: 2px;
            }
          }
  
          i {
            font-size: 16px;
            color: $gray-color-2;
  
            &[class*="icon-video"] {
              font-size: 14px;
            }
          }
  
          h2 {
            margin-bottom: 0;
            flex: 1 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: $secondary-color;
            font-size: 20px;
            line-height: 23px;
          }
  
          .number-icon {
            font-size: 16px;
            font-weight: 500;
            background-color: $gray-color-1;
            color: $gray-color-2;
            margin-right: 3rem;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius: 2px;
          }
  
          .stimuli {
            padding-left: 9rem;
            font-size: 12px;
            font-weight: 400;
  
            p:first-of-type {
              color: $secondary-color;
            }

            ul {
              margin: -2rem 0 3rem 3rem;
            }
          }
        }
  
        .stimuli-container {
          ul {
            margin: -2rem 0 3rem;
            padding-left: 12rem;
          }

          .stimuli {
            display: flex;
            align-items: center;
            margin-left: 6rem;

            p {
              margin: 0 0 3rem;

              &:first-child {
                font-size: 15px;
                flex: 1;
              }
  
              &:last-child {
                font-size: 13px;
                font-weight: 300;
              }
            }
  
           
          }
  
          &:last-child .separator {
            display: none;
          }
        }
      }
    }
  }
}

.publish-test-message {
  margin-top: -12rem;
  background-color: $warning-color;
  color: $white-color;

  .content-wrapper {
    padding: 2rem 3rem;
    display: flex;
    align-items: center;

    h3 {
      margin: 0;
      color: $white-color;
    }

    p {
      margin: 0;
    }

    i {
      margin-right: 3rem;
      color: $white-color;
      font-size: 16px;
    }

    .content-text {
      flex: 1;
    }

    button.secondary {
      margin: 0;
      border-color: $white-color;
      color: $white-color;
    }
  }
}

.publish-test-box.pay {
  background: $white-color;
  color: $text-color;
  font-weight: 300;
  padding: 4rem 4rem;
  // margin-top: -2rem;
  margin-bottom: 14rem;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-bottom: 8rem;
  border-bottom: 1px $gray-color-2 solid;

  h2 {
    color: $featured-color;
  }

  .number-of-responses {
    flex: 1;
    height: auto;
    // padding: 2rem 4rem 2rem 2rem;
    text-transform: none;
    margin-right: 2rem;
    background: transparent;
    // color: #777782;
    // border: 1px #777782 solid;
    

    .title {
      font-size: 16px;
      color: $white-color;
      margin-bottom: 2rem;
    }

    .value {
      // align-items: center;
      display: flex;

      input {
        margin-right: 6rem;
        height: 46px;
        border-radius: 6px;
        width: 100px;
        text-align: center;
        font-size: 18px;
      }
    }

    .MuiSlider-valueLabelLabel {
      font-size: 12px;
    }
    
    .MuiSlider-root {
      font-size: 12px;
      color: $gray-color-2;
      height: 14px;
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 0;

      .MuiSlider-track {
        background-color: $featured-color;
        border: 0;
      }

      .MuiSlider-mark {
        display: none;
      }
    
      .MuiSlider-markLabel {
        margin-top: -8px;
        font-size: 12px;
        color: $text-color;
      }
    }
  }

  .pay-resume {
    margin-top: 6rem;

    table {
      border: 1px $gray-color-2 solid;
      outline: none;
      padding: 0;

      .qty-col {
        width: 40px;
      }

      .description-col {
        width: 50%;
      }

      td {
        border-left: 1px $gray-color-2 solid;
      }

      td:first-child {
        border-left: 0;
      }

      td.with-input {
        padding: 0 2px;
      }

      input {
        width: 100%;
        padding: 0 4rem;
        box-sizing: border-box;
        font-size: 15px;
      }
    }
  }

  i {
    font-size: 20px;
    color: $white-color;
    margin-top: -4px;
    margin-right: 4rem;
  }

  .publish-test-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .content-text { 
    flex: 1;
  }

  h3 {
    margin-top: 6rem;
    margin-bottom: 3rem;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }

  p {
    margin: 0;
    font-size: 16px;

    b {
      color: #000000;
    }
  }

  button {
    background-color: $featured-color;
  }

  button.secondary {
    margin-top: 0;
    color: #ffffff;
    border-color: #ffffff;
    height: 48px;
    font-size: 13px;
  }

  input {
    border: 1px $gray-color-2 solid;
  }
}

.content-wrapper.pay .pay-widget {
  margin-top: 0;
  border: 2px #06c7e7 solid;
}

.content-column {
  flex: 1;
}

.ads-column {
  width: 270px;
  margin-left: 6rem;
}

.alyze-ad-placeholder {
  background: $white-color;
  border: 1px $gray-color-2 solid;
  width: 100%;
  height: 460px;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  text-align: center;

  p {
    margin: auto;
    font-size: 12px;
    color: $gray-color-2;
  }
}

.title-with-buttons {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5rem;

  button {
    margin-left: 4rem;

    &.featured i {
      font-size: 15px;
    }

    i {
      margin-top: -2px;
      margin-right: 1rem;
      margin-left: -1rem;
    }

    i.icon-plus-1 {
      font-size: 17px;
      margin-top: -3px;
    }

    .button-text {
      display: flex;
      align-items: center;
      padding-top: 1px;
    }
  }

  h2 {
    margin: 0 !important;
    flex: 1;
  }
}

.audience-actions-left {
  flex: 1;
}

.recopilator {
  &.draft {
    .button-toggle:hover {
      cursor: default;
    }
  }

  .content-widget.link {
    background: transparent;
    padding: 0;
    position: relative;
    margin: 10rem 0;
    border-radius: 0;
    border: 0;

    &.disabled {
      .link {
        background-color: #f8f8f8;
        border-color: #dfe0e0;
      }

      .link i,
      .metrics,
      .alias-name,
      .active .label {
        opacity: 0.6;
      }
    }

    &.add-new {
      display: flex;
      border: 2px dashed $main-color;
      padding: 4rem;
      align-items: center;
      border-radius: 6px;

      &:hover {
        filter: brightness(110%);
      }

      .add-new-wrapper {
        // transform: scale(0.8);
        width: 100%;

        pointer-events: none ;
      }

      .add-new-label {
        .icon-wrapper {
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;

          i {
            font-size: 26px;
          }
        }
  
        .label {
          font-size: 18px;
        }
      }
    }

    .alias {
      display: flex;
      align-items: flex-end;
      padding: 0;
      margin: 0;
      border: 0;
      margin-bottom: 3rem;

      .alias-name span {
        font-size: 20px;
      }

      .active .label {
        font-size: 13px;
      }
    }

    .link {
      background: #fff;
      padding: 1rem 3rem 1rem 3rem;
      border: 1px #D4D6D7 solid;
      margin-bottom: 2rem;

      .icon {
        font-size: 20px;
        margin-right: 2rem;
      }

      .button {
        padding: 0 3rem;
      }

      input {
        background: transparent;
        font-size: 16px;
      }
    }

    .metrics {
      background: #fff;
      border: 1px #D4D6D7 solid;
      height: 82px;
      // border-radius: 6px;
      overflow: hidden;

      .title {
        font-size: 11px;
        font-weight: 300;
      }

      .col:not(.featured) {
        margin: 2rem 0;
      }
    }
  }
}

.recopilator-choose-wrapper {
  .recopilator-text {
    margin-top: 12rem;
    margin-bottom: 8rem;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .recopilator-boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .recopilator-box {
      flex: 1;
      position: relative;
      padding: 12rem 8rem;
      background-color: $white-color;
      color: $secondary-color;
      border: 1px solid $gray-color-2;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin-bottom: 8rem;
      text-align: center;
      border-radius: 6px;
      box-sizing: border-box;
      height: 448px;

      &.featured {
        border-color: $featured-color;

        h2 {
          color: $featured-color;
        }

        .recopilator-box-tag {
          background-color: $featured-color;
        }
      }

      &.featured:hover {
        border-color: $featured-color;
        
        .recopilator-box-tag {
          background-color: $featured-color;
        }
      }

      &:hover {
        border-color: $main-color;

        .recopilator-box-tag {
          background-color: $main-color;
        }
      }

      h2 {
        color: inherit;
        font-size: 20px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 4rem;
      }

      p {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 0;
      }
    }

    .recopilator-box.disabled {
      pointer-events: none;
      opacity: .4;
    }

    .recopilator-box:not(:last-child) {
      margin-right: 8rem;
    }

    .recopilator-box-tag {
      position: absolute;
      top: -1px;
      left: -1px;
      background-color: darken($gray-color-2, 6.2%);
      color: $white-color;
      padding: 2rem 3rem;
      font-size: 11px;
      line-height: 13px;
      border-radius: 6px 0 0;
    }

    .recopilator-box-icon {
      margin-bottom: 8rem;

      img {
        max-width: 150px;
      }
    }

    @media (max-width: 980px) {
      flex-direction: column;
      margin-left: 5rem;
      margin-right: 5rem;

      .recopilator-box:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

.recopilator-graph {
  height: 400px;

  @media (max-width: 600px) {
    &.responsive-chart.total-responses {
      height: 300px;
    }
  }
}

.recopilator-tooltip {
  display: flex;
  align-items: center;

  .recopilator-tooltip-interaction {
    padding: 6px;
    display: inline-flex;
    margin-right: 5px;
  }
}

.recopilator-tooltip.recopilator-tooltip-line {
  background: $white-color;
  padding: 5px; 
  box-shadow: 1px 1px 3px $gray-color-2;
}

.metrics-tooltip-color {
  display: inline-block;
  background: red;
  width: 12px;
  height: 12px;
  margin-right: 2rem;
  position: relative;
  top: 1px;
}

/*
.metrics-tooltip:before {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, 0) rotate(45deg);
  background: #EDEDF1;
  z-index: -1;
}
*/

.metrics-tooltip {
  background: #EDEDF1;
  padding: 1rem 2rem 2rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  position: relative;

  .metrics-tooltip-interaction {
    display: flex;
    flex-direction: row;

    b {
      margin-left: 5px;
    }
  }

  video, img {
    max-width: 260px;
    max-height: 260px;
    margin-left: 2rem;
  }

  .bold,
  b {
    font-weight: 600;
  }

  .color {
    display: inline-block;
    background: red;
    width: 12px;
    height: 12px;
    margin-right: 2rem;
    position: relative;
    top: 1px;
  }

  .interaction-name {
    font-size: 14px;
    margin-bottom: 2rem;
    margin-top: 1rem;

    span.sec {
      padding: 2px 8px;
      border-radius: 6px;
      background: #dddddd;
      margin-left: 1rem;
      /* float: right; */
    }
  }

  .time {
    margin-left: 2rem;
  }

  .metrics-tooltip-interaction {
    margin-top: 2rem;

    hr {
      margin: 2rem 0;
    }

    p {
      width: 100%;
      display: block;
      margin: 1px 0;

      b {
        margin-left: 1rem;
        color: #14151C;
      }
    }

    p.positive,
    p.positive b {
      /* color: #56e661; */
    }

    p.negative,
    p.negative b {
      /* color: #E6565A; */
    }
  }

  .metrics-tooltip-interaction.no-margin {
    margin-top: 2rem;
  }

  .metrics-tooltip-interaction:first-child,
  .metrics-tooltip-interaction.no-margin:first-child {
    margin-top: 0;
  }
}

.responsive-chart {
  position: relative;

  .loading-cover {
    position: absolute;

    .cover {
      display: none;
    }
  }
}

.responsive-chart.table {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 0rem;

  .flex-content {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
  }

  .legend {
    padding: 3rem 0rem 2rem 0;
    color: #7f7d86;
    text-align: right;
    font-size: 11px;
    line-height: 18px;

    div {
      margin-bottom: 3rem;
      padding-bottom: 4rem;
      padding-right: 4rem;
      border-bottom: 2px #262934 solid;
    }

    div:last-child {
      border-bottom: 0;
    }
  }

  .chart-item-row {
    color: #EDEDF1;
    flex: 1;
    padding: 3rem 0rem 1rem;

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #7f7d86;
    }

    div {
      margin-bottom: 3rem;
      padding-bottom: 4rem;
      padding-left: 4rem;
      border-bottom: 2px #262934 solid;
    }

    div:last-child {
      border-bottom: 0;
    }

    span {
      color: #7f7d86;
    }
  }

  .color {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 3rem 0 0;
    position: relative;
    top: 1px;
  }

  .chart-item-row div.metric,
  .legend div.metric {
    height: 20px;
    font-size: 15px;
    color: #7f7d86;
    padding-top: 4rem;
    background: #ffffff14;
    border-bottom: 0;
  }
}

.responsive-chart.table.metric-peak {
  padding-top: 0;
}

.color-0 {
  background: #e8c1a0;

  p {
    /* color: #e8c1a0; */
  }
}

.color-1 {
  background: #f47460;

  p {
    /* color: #f47460; */
  }
}

.color-2 {
  background: #f1e15b;

  p {
    /* color: #f1e15b; */
  }
}

.color-3 {
  background: #e8a838;

  p {
    /* color: #e8a838; */
  }
}

.color-4 {
  background: #60cdbb;

  p {
    /* color: #60cdbb; */
  }
}

.color-5 {
  background: #97e3d6;

  p {
    /* color: #97e3d6; */
  }
}

.color-6 {
  background: #e8c1a0;

  p {
    /* color: #e8c1a0; */
  }
}

.color-7 {
  background: #f47460;

  p {
    /* color: #f47460; */
  }
}

.color-8 {
  background: #f1e15b;

  p {
    /* color: #f1e15b; */
  }
}

.color-9 {
  background: #e8a838;

  p {
    /* color: #e8a838; */
  }
}

.color-10 {
  background: #60cdbb;

  p {
    /* color: #60cdbb; */
  }
}

.color-11 {
  background: #97e3d6;

  p {
    /* color: #97e3d6; */
  }
}

.color-12 {
  background: #e8c1a0;

  p {
    /* color: #e8c1a0; */
  }
}

.screen-metrics {
  @media (max-width: 1024px) {
    .loading-cover {
      position: absolute;
    }
  }

  .screen-content {
    .loading-cover .cover {
      background: $background-color;
      opacity: 0.6;
    }

    .no-results-msg {
      padding: 0 4rem 10rem;

      p {
        margin-top: 0;
        margin-bottom: 5rem;
      }

      .button {
        i {
          margin-top: -3px;
          margin-left: 2rem;
          margin-right: -5px;
          font-size: 14px;
        }
      }

      @media (max-width: 1024px) {
        button {
          width: 100%;
        }
      }
    }
  }

  .content-widget {
    // border: 0;
    // background: transparent;
  }

  .screen-content-inner > .loading-cover {
    right: 340px;
  }
}

.top-locations,
.age-range,
.question-results {
  .metrics-tooltip {
    padding: 0;
    margin: 0;
    background: none;
    border: 0;
  }
}

.responsive-chart {
  display: flex;

  .flex {
    flex: 1;
    position: relative;
  }

  .answers-legend {
    color: #7f7d86;
    /* padding-left: 6rem; */

    .box {
      /* padding: 3rem; */
      padding: 1rem 1rem 2rem;
      /*
      background: white;
      height: auto;
      */
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      /* flex-direction: row-reverse; */
      margin-right: -8rem;

      li {
        display: flex;
        margin-bottom: 3rem;
        margin-right: 8rem;
        /* max-width: calc(33.333% - 8rem); */

        display: flex;
        /* margin-bottom: 3rem; */
        margin-right: 6rem;
        /* max-width: calc(33.333% - 8rem); */
        // border: 1px #EDEDF1 solid;
        // border-radius: 6px;
        // padding: 2rem 3rem;

        p {
          margin: 0;
          flex: 1;
          color: rgb(127, 125, 134);
        }
      }

      li:hover {
        cursor: pointer;
      }

      li.inactive {
        p {
          color: #7f7d86 !important;
          opacity: 0.4;
        }

        .color {
          border-color: #7f7d86 !important;
          width: 10px;
          height: 10px;
          background: transparent !important;
          opacity: 0.4;

          i {
             display: none;
          }
        }
      }

      /*
      li:first-child {
        margin-right: 0;
      }
      */
    }

    .toggle-show {
      margin-left: 2rem;
      font-size: 15px;
    }

    .toggle-show.inactive {
      opacity: 0.4;
    }

    .color {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 0 3rem 0 0;
      position: relative;
      top: 3px;
      border-radius: 2px;
    }

    .color-0 {
      background: #9e0142;
    }

    .color-1 {
      background: #d53e4f;
    }

    .color-2 {
      background: #f46d43;
    }

    .color-3 {
      background: #fdae61;
    }

    .color-4 {
      background: #fee08a;
    }

    .color-5 {
      background: #fffebf;
    }
  }
}

.responsive-chart.emotional-overview {
  .answers-legend {
    .color {
      display: inline-block;
      border: 1px #7f7d86 solid;
      width: 10px;
      height: 10px;
      margin: 0 3rem 0 0;
      position: relative;
      top: 3px;
      border-radius: 2px;

      i {
        position: relative;
        top: -5px;
        left: -4px;
        font-size: 12px;
      }
    }
  }
}

.global-summary {
  color: #7f7d86;
  display: flex;
  margin-top: 6rem;
  margin-bottom: 6rem;

  div {
    flex: 1;
    text-align: center;

    i {
      font-size: 30px;
      background: none;
    }

    .label {
      margin-bottom: 1rem;
    }

    .big-percentage {
      font-weight: 500;
      font-size: 36px;
      margin-bottom: -8px;
      color: #EDEDF1;
      background: none;
    }

    .value {
      font-weight: 400;
      font-size: 22px;
      margin-top: 0px;
      color: #EDEDF1;
    }

    .color-0 {
      color: #9e0142;
    }
    
    .color-1 {
      color: #d53e4f;
    }
    
    .color-2 {
      color: #f46d43;
    }
    
    .color-3 {
      color: #fdae61;
    }
    
    .color-4 {
      color: #fee08a;
    }
    
    .color-5 {
      color: #fffebf;
    }
  }
}

.changes-status {
  color: #717171;

  i {
    color: #717171;
    margin-right: 1rem;
  }
}

.changes-status.saving {
  color: #EDEDF1;

  i {
    color: #EDEDF1;
    margin-right: 1rem;
  }
}

.float-right-bottom-content {
  position: absolute;
  bottom: 10rem;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);

  .main-button i {
    margin: 0 -8px 0 1rem;
  }
}

.right-bottom-content {
  margin-bottom: 10rem;
  margin-top: -2rem;

  .main-button i {
    margin: 0 -8px 0 1rem;
  }
}

.field.preview-url {
  .input-icon,
  .button-upload-image {
    pointer-events: none;
  }

  input {
    padding-right: 198px;
    text-overflow: ellipsis;
  }

  input:hover {
    cursor: pointer;
  }
}

.field.preview-url:hover {
  .button-upload-image {
    color: #EDEDF1;
  }
}

.separator {
  border-bottom: 1px #373940 solid;
  margin-bottom: 6rem;
}

.interes-links {
  display: flex;
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: 6rem;

  a {
    background: $white-color;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    text-align: center;
    flex: 1;
    color: $text-color;
    border: 1px $gray-color-2 solid;
    border-radius: 2px;
    margin: 0 2rem;
    padding: 4rem 0 1rem;

    div {
      flex: 1;
      text-align: center;

      img {
        width: 160px;
        margin: auto;
      }

      .icon {
        font-size: 28px;
      }
    }

    p {
      padding: 2rem 0 4rem;
      margin: 0;
    }
  }

  a:hover {
    border: 1px $main-color solid;

    p, i {
      color: $main-color;
    }
  }

  .faq img {
    width: 130px;
  }
}


body .introjs-helperLayer {
  background-color: transparent;
  border: 2px solid #278DF3;
}


body .introjs-helperNumberLayer {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #EDEDF1;
  text-align: center;
  text-shadow: none;
  background: #278cf3;
  border: none;
}

.early-access-text {
  color: #7f7d86;
  margin-top: -2rem;
  margin-bottom: 6rem;
  font-size: 12px;
  text-align: left;

  button.inline {
    color: #278DF4;
    padding: 0;
    margin: 0;
    font-weight: 500;
  }

  .button.inline:hover {
    color: #4aa1fb;
  }
}

.content-widget p.audience-info {
  color: #7f7d86;
  margin-bottom: 5rem;
}

.content-widget p.audience-info.last {
  margin-bottom: 2rem;
}

h2 .tooltip-info {
  margin-left: 1rem;
}

.__react_component_tooltip.show {
  opacity: 1;

  .multi-line {
    text-align: left;
  }
}

.interpretation-guide {
  outline: 0px red solid;
  position: absolute;
  z-index: 0;
  top: 20px;
  right: 18px;
  bottom: 59px;
  /* width: 20px; */
  display: flex;
  flex-direction: column;

  div {
    flex: 1 1;
    background: rgb(127, 125, 134);
    opacity: 1;
    margin: 4px 0;
    width: 1px;
    position: relative;
    display: flex;
    text-align: center;
    align-items: center;
    z-index: 6;
    position: relative;

    p {
      transform: rotate(-90deg);
      position: relative;
      font-size: 12px;
      font-family: sans-serif;
      color: #7f7d86;
      transform: rotate(-90deg);
      text-align: center;
      width: auto;
    }
  }

  .positive {
    background: #56e661;

    p {
      /* color: #56e661; */
    }
  }

  .neutral {
    height: 5rem;
    flex: initial;
    /* background: #f08c1e; */

    p {

    }
  }

  .negative {
    background: #E6565A;

    p {
      /* color: #E6565A; */
    }
  }
}

.timeline-wrapper {
  outline: 0px red solid;
  position: absolute;
  z-index: 1;
  top: 11px;
  right: 20px;
  bottom: 51px;
  left: 60px;
  pointer-events: none;

  .time-position {
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 0px;
    margin-left: -1px;
    border-right: 2px #EDEDF1 dashed;
    /* background: #FF9800; */
    position: absolute;

    .time-second {
      position: absolute;
      background: #14151c;
      bottom: 0;
      color: #EDEDF1;
      padding: 0px 2px;
      /* border-radius: 6px; */
      left: 0;
      transform: translate(-50%, 20px);
      font-size: 12px;
      font-family: sans-serif;
    }
  }
}

.screen-metrics {
  #videoFrame {
    height: 0;
    position: fixed;
  }
}

.screen-metrics.show-frame {
  #videoFrame {
    height: inherit;
    position: relative;
  }

  #videoPreview {
    height: 0;
    position: fixed;
  }
}

.left-content.title {
  flex: 0;
}

.left-content.modules {
  /* align-items: center; */
  margin-left: 12rem;
  padding-left: 6rem;
  border-left: 1px #33333c solid;
}

.modules-navigation {
  display: flex;
  margin-top: 2px;
  margin-left: -2rem;

  background: $white-color;
  border-bottom: 1px $gray-color-2 solid;
  padding: 4rem 4rem;
  margin-top: -12rem;
  overflow: auto;

  .modules-navigation-center {
    display: flex;
    /* margin: auto; */
  }

  i {
    color: $gray-color-2;
    font-size: 16px;
    margin-top: 2px;
    margin-top: 4px;
  }

  .module {
    padding: 1rem 3rem;
    border-radius: 2px;
    border: 1px $gray-color-2 solid;
    color: $text-color;
    margin: 0 2rem;
    display: flex;
  }

  .module.all {
    margin-right: 6rem;
  }

  .module:hover {
    cursor: pointer;
  }

  .module.active {
    color: #ffffff;
    border-color: #1c1d2e;
    background: #1c1d2e;
    /* padding: 1rem 3rem 1rem 2rem; */

    i {
      display: none;
      color: #ffffff;
      font-size: 16px;
      margin-right: 2rem;
    }
  }
}

.realtime-metric {
  max-height: 250px;
  padding: 0 6rem;
}

.flex.hide,
.preview-placeholder.hide {
  opacity: 0;
}

.preview-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  display: flex;
  flex-direction: row;

  div {
    margin: auto;
    transform: translateY(-6rem);
    text-align: center;

    img {
      width: 70px;
      margin-bottom: 6rem;
    }

    h2 {
      color: #EDEDF1;
      font-weight: 400;
    }

    p {
      color: #7f7d86;
      margin: auto;
    }
  }
}

.wallet:hover {
  cursor: pointer;
}

.wallet {
  border: 2px #7f7d86 solid;
  padding: 0 2rem;
  border-radius: 20px;
  font-size: 16px;
  color: #EDEDF1;
  display: flex;
  margin-top: 22px;
  margin-right: 4rem;
  height: 34px;
  line-height: 34px;

  img {
    width: 16px;
    height: auto;
    margin-right: 2rem;
    position: relative;
    top: -2px;
  }

  span.text {
    font-size: 12px;
    margin-right: 2rem;
    color: #7f7d86;
  }

  .amount {
    color: #EDEDF1;
    font-size: 16px;
    margin-left: 2rem;
  }

  .btn {
    margin-left: 2rem;
    border-left: 1px #7f7d86 solid;
    padding-left: 1rem;

    i:before {
      font-size: 16px;
      line-height: 41px;
    }
  }
}

.test-duration-wrapper {
  position: absolute;
  right: 4rem;
  top: 4rem;
  z-index: 2;
  background: $white-color;
  border: 1px $gray-color-2 solid;
  padding: 2rem 3rem;
  border-radius: 0px;
  // transition: right 0.3s ease-in-out;

  i.icon-info-4 {
    margin-left: 1rem;
    position: relative;
    top: 2px;
  }
}

.flow-canvas-wrapper {
  // transition: right 0.3s ease-in-out;
}

.panel-open-qa-screen,
.panel-open-text-screen,
.panel-open-film-screen,
.panel-open-audio-screen,
.panel-open-image-screen,
.panel-open-video-screen {
  .test-duration-wrapper {
    right: 380px;  
  }

  .flow-canvas-wrapper {
    right: 360px;
  }
}

.test-duration {
  text-align: left;

  p {
    margin: 2rem 0 0;
  }

  .title {
    color: $secondary-color;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    span {
      flex: 1;
    }
  }

  .legend-info {
    color: $featured-color;
    font-size: 10px;
    display: inline-block;
  }

  .test-duration-bar {
    background: $gray-color-2;
    width: 200px;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    .legend {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white-color;
      font-weight: 500;
      width: 100%;
      text-align: center;
    }

    .plan-limit {
      left: 70%;
      width: 2px;
      background: $featured-color;
      position: absolute;
      height: 100%;
    }

    .progress {
      width: 0%;
      max-width: 100%;
      height: 100%;
      background: $featured-color;
    }
  }
}

.test-duration.non-included {
  .legend-info {
    color: $error-color;
  }
  .progress {
    background: $error-color;
  }
}



  input {
    height: 46px;
  }

  .loading-cover {
    .rc-progress-circle {
      position: relative;
      width: 200px;

      .rc-progress-circle-trail {
        opacity: 0.1;
      }
    }

    .loading-text {
      color: $white-color;
      position: absolute;
      text-align: center;
      font-size: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .animated {
        color: $white-color;
        font-size: 28px;
        line-height: 16px;
        height: 16px;
        margin-top: 2rem;
        margin-bottom: 0;
      }
    }
  }

  .file {
    padding: 0 0 0 2rem;
    height: 50px;
    background: $white-color;
    display: flex;
    align-items: center;
    border: 1px $gray-color-2 solid;
    font-size: 12px;
    border-radius: 2px;

    .url-wrapper {
      margin-right: 2rem;
      flex: 1;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      direction: rtl;
      text-align: left;
    }

    i {
      margin-right: 2rem;
    }

    button {
      height: 50px;
      border-radius: 0 2px 2px 0;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .fluid {
    // flex: 1;
    // text-align: center;
    // position: relative;
    // overflow: auto;

    .loading-cover {
      // position: absolute;
    }

    .media-placeholder {
      width: 180px;
      opacity: 0.4;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    h3 {
      font-size: 16px;
      font-weight: 400;
      color: $secondary-color;
      margin-top: 6rem;
      margin-bottom: 3rem;
    }

    // .fluid-wrapper {
    //   padding: 0 6rem;
    // }

    .button.secondary {
      margin-top: 3rem;
    }

    .button-add-file {
      display: flex;
      text-align: left;
      margin-top: 6rem;
      margin-bottom: 8rem;
      align-items: center;

      .circle-wrapper {
        text-align: center;
        padding: 0;
        line-height: 40px;
        width: 40px;
        height: 40px;
        border-radius: 25px;
        background: $main-color;

        i {
          font-size: 22px;
          color: $white-color;
          margin: auto;
        }
      }

      .text {
        margin-left: 3rem;
        flex: 1;
        font-size: 15px;
        font-weight: 400;
      }
    }
  }

  .fluid.white {
    background: $white-color;
  }

  .fixed-bottom {
    // padding: 6rem 6rem;
    padding: 4rem;
    background: $white-color;
    text-align: center;

    .row {
      margin-left: -2rem;
      margin-right: -2rem;
      width: auto;

      button {
        margin: 0 2rem;
        margin-bottom: 0;
      }
    }
  }

  // h2 {
  //   color: $secondary-color;
  //   font-size: 20px;
  //   font-weight: 400;
  //   margin-top: 0;
  //   margin-bottom: 2rem;
  // }

  // p {
  //   color: $text-color;
  //   font-size: 14px;
  //   margin-bottom: 6rem;
  // }

  .lite-topbar {
    height: 70px;
    background: $white-color;
    display: flex;
    align-items: center;

    .logo {
      margin: auto;
      width: 70px;
    }
  }

  .screen-description {
    background: white;
    text-align: center;
    padding: 4rem 6rem 0;
  }

  .loading-cover {
    z-index: 1;
  }

  .actions {
    display: none;
  }

  .fluid-wrapper {
    // padding: 2rem 0 0;
    padding: 3rem 0 0;
  }

  .content-widget {
    // padding: 2rem 2rem 5rem 2rem;
  }

  .responsive-chart .answers-legend {
    padding: 0 4rem;

    ul {
      flex-direction: column;
      text-align: left;
    }
  }

// .app:not(.screen-metrics) {
//   max-width: 500px;
//   margin: auto;

//   button,
//   button.secondary {
//     margin-top: 0;
//     height: 50px;
//   }

//   // button {
//   //   font-size: 12px;
//   //   height: 46px;
//   // }

//   button:not(:last-child) {
//     margin-bottom: 4rem;
//   }
// }

.intercom-lightweight-app {
  display: none !important;
}

#fg_modal {
  display: none !important;
}

.mobile-topbar {
  box-shadow: 0 0 8px #00000030;
  position: relative;
  z-index: 2;
  display: none;

  .fluid-width {
    flex: 1;
    min-width: 0;
  }

  i {
    font-size: 22px;
  }

  .user-options-menu {
    right: 0rem;
    top: 8rem;
  }

  .main-topbar {
    padding: 5rem 2rem 5rem 4rem;
    display: flex;
    height: 80px;
    box-sizing: border-box;
    background-color: $secondary-color;

    i {
      color: $gray-color-1;
      font-size: 20px;
    }

    .logo {
      width: 70px;
    }
  }

  .content-topbar {
    padding: 0 2rem 0 0;
    display: flex;
    height: 65px;
    box-sizing: border-box;
    background-color: $white-color;
    display: flex;
    align-items: center;
    border-bottom: 1px $gray-color-2 solid;

    &.add-padding {
      padding: 0 4rem;
    }

    i {
      color: $secondary-color;
    }

    .back-button {
      width: 56px;
      display: flex;
      align-items: center;

      i {
        color: $text-color;
        margin: auto;
      }
    }

    .subtitle {
      font-size: 13px;
      font-weight: 600;
      color: $text-color;
    }

    .title {
      font-size: 18px;
      font-weight: 400;
      color: $secondary-color;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .content-topbar.enable-demo {
    height: 42px;
    padding-right: 4rem;
    background: #edeeee;

    .label {
      line-height: inherit;
    }

    &.show-demo-data {
      background-color: #F39F4D;
      border-bottom-color: #F39F4D;
      background-image: linear-gradient(135deg, #e28a33 5.56%, #F39F4D 5.56%, #F39F4D 50%, #e28a33 50%, #e28a33 55.56%, #F39F4D 55.56%, #F39F4D 100%);
      background-size: 11px 11px;
      color: $white-color;
    }
  }

  .view-options {
    height: 46px;
    padding: 1rem 3.7rem 2rem 5rem;

    .title i {
      position: relative;
      top: 2px;
    }
  }

  .btn-icon {
    width: 46px;
    text-align: center;
    position: relative;

    i {
      color: $text-color;
      font-size: 20px;
    }
  }

  .btn-toggle-media-preview {
    width: auto;
    display: flex;

    .label {
      font-size: 13px;
      font-weight: 500;
      line-height: 30px;
      margin-right: 2rem;
    }

    i {
      font-size: 20px;
    }
  }
}

.screen-content.show-demo-data {
  border: 2px #F39F4D solid;
  border-top: 0;

  .show-demo-data-bottom {
    position: fixed;
    bottom: 0;
    border-bottom: 4px #F39F4D solid;
    width: 100%;
    z-index: 11;
  }

  @media (max-width: 620px) {
    .content-wrapper {
      margin-left: -4px;
      margin-right: -4px;
    }
  }

  @media (min-width: 1024px) {
    margin-top: -12rem;
    padding-top: 12rem;
    border-bottom: 0;
    border-width: 4px;
    // topBar + contentHeader + margin + margin
    min-height: calc(100vh - (60px + 166.5px + 60px + 60px));
  }
}

.segment-name {
  margin: -3rem -3rem 3rem -3rem;
  background: $gray-color-1;
  padding: 2rem 3rem;
  border-top: 1px $gray-color-2 solid;
  border-bottom: 1px $gray-color-2 solid;
}

.stimulis.qa {
  .segment-name {
    border-top: 0;
    margin: 0;
  }

  .metrics-item {
    padding: 3rem 3rem 0 3rem;
  }

  &:last-child {
    .metrics-item {
      padding-bottom: 3rem;
    }
  }
}

.metrics-item {
  padding: 3rem 3rem 3rem;
  background-color: $white-color;
  // border-bottom: 1px $gray-color-2 solid;
  text-align: left;
  // margin-bottom: 2rem;

  .loader-widget {
    background: $gray-color-1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    height: auto;
  }

  &.recognition:not(:last-child) {
    border-bottom: 1px $gray-color-2 solid;
  }

  .two-columns.stimulis {
    margin: 0 -3rem -3rem -3rem;

    .column-a, .column-b {
      padding: 3rem;
    }
  }

  .stimulus-title {
    display: flex;
    justify-content: space-between;
  }

  .item-name {
    font-size: 16px;
    font-weight: 500;
    color: $secondary-color;
    margin-bottom: 2rem;

    .position {
      font-weight: 400;
      color: $text-color;
      margin-right: 2rem;
      display: none;
    }
  }

  .image,
  .audio {
    position: relative;
    height: 140px;
    background: $gray-color-1;
    background-color: black;
    margin-bottom: 3rem;
    text-align: center;
    // display: none;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    border-radius: 2px;

    .btn-play-media {
      position: absolute;
      color: #ffffff;
      border: 1px white solid;
      padding: 2rem;
      background: #00000059;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .media-type {
      position: absolute;
      top: 1rem;
      right: 1rem;
      // background: $white-color;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      align-items: center;

      i {
        // color: $text-color;
        color: $white-color;
        margin: auto;
        text-shadow: 0 0 20px black;
      }
    }

    video, img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* filter: blur(8px); */
    }
  }

  .audio {
    background: #f1f3f4;
    height: 54px;
  }

  .image.hide,
  .audio.hide {
    display: block;
    height: 0;
    margin: 0;
    opacity: 0;
  }

  .metric-content {
    display: flex;

    .image {
      // display: none;
      flex: none;
      border: 1px $gray-color-1 solid;
      border-radius: 2px;
      width: 50px;
      height: auto;
      margin-right: 3rem;
    }

    .score {
      border-radius: 2px;
      font-size: 28px;
      font-weight: 500;
      color: #ffffff;
      padding: 0;
      margin-right: 3rem;
      line-height: 100%;
      /* display: flex; */
      /* align-items: center; */
      width: 60px;
      box-sizing: border-box;
      text-align: center;
      line-height: 55px;

      span {
        display: inline-block;
        margin-top: 5px;
        margin-left: 1rem;
        font-size: 16px;
        opacity: 0.6;
        display: none;
      }

      .score-title {
        border-radius: 2px;
        font-size: 16px;
        font-weight: 600;
        color: $white-color;
      }
    }

    .score-resume {
      /* flex: none; */
      flex: 1;
      margin-right: 2rem;

      .score-title {
        border-radius: 2px;
        font-size: 16px;
        font-weight: 600;
        color: $white-color;
        display: inline-block;
        padding: 0 1rem;
      }

      .score-legend {
        margin-top: 1rem;
        margin-bottom: -4px;
        font-size: 12px;
      }
    }
  }

  .testers-score {
    flex: none;
    // width: 95px;
    display: flex;
    flex-direction: column;
  }

  // .score-legend {
  //   /* margin: 0; *//* display: none; */
  //   font-size: 12px;
  //   margin-top: 0;
  //   margin-bottom: 0;
  //   margin-bottom: 1px;
  // }

  .score-metric {
    width: 100%;
    height: 100%;
    height: 200px;
    flex: 1;
    display: flex;
    flex-wrap: wrap-reverse;
    position: relative;

    div {
      background-color: $gray-color-1;
      flex: 1;
      width: 6px;
      margin-right: 4px;
      // width: 20px;
      // margin-right: 2px;
      min-height: 2px;
      height: 2px;
      transition: all 0.6s;
    }

    div:last-child {
      margin-right: 0;
    }

    &.loading div {
      background-color: $gray-color-1;
    }

    .bar-percentage {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      bottom: inherit !important;
      text-align: center;
      background: transparent;
      font-size: 10px;
      margin-bottom: 2px;
      display: none;
    }

    .bar-legend {
      position: absolute;
      bottom: -17px;
      text-align: center;
      background: transparent;
      font-size: 10px;
      display: none;
    }

    .bar-1 {
      background-color: #DF4C5D;
    }

    .bar-2 {
      background-color: #E66957;
    }

    .bar-3 {
      background-color: #EC8352;
    }

    .bar-4 {
      background-color: #F39F4D;
    }

    .bar-5 {
      background-color: #F9B859;
    }

    .bar-6 {
      background-color: #FFD158;
    }

    .bar-7 {
      background-color: #D9CA44;
    }

    .bar-8 {
      background-color: #B8C842;
    }

    .bar-9 {
      background-color: #98C740;
    }

    .bar-10 {
      background-color: #72C53E;
    }
  }

  .metric-details {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: -3rem;

    .metric-details-title {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 13px;
      display: flex;
      justify-content: flex-end;

      .button-tab {
        position: relative;
        line-height: 56px;
        height: 56px;
        margin-left: 5rem;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }

        &.active {
          color: $main-color;

          &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 4px;
            background-color: $main-color;
          }
        }
      }
    }

    .metric-details-content {
      display: none;
      border-top: 1px solid $gray-color-2;
      margin: 0 -3rem;
      padding: 3rem 3rem 5rem;

      .no-results-msg {
        padding-top: 5rem;
        padding-bottom: 2rem;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }

      &.active {
        display: block;
      }
    }
  }
}

.score-title,
.score,
.metric-bar .bar  {
  background-color: $gray-color-2;
}

.metric-content.good,
.metrics-item.good {
  .score-title,
  .score,
  .metric-bar .bar  {
    background-color: #68C53E;
  }
}

.metric-content.medium,
.metrics-item.medium {
  .score-title,
  .score,
  .metric-bar .bar  {
    background-color: #FECB45;
  }
}

.metric-content.bad,
.metrics-item.bad {
  .score-title,
  .score,
  .metric-bar .bar {
    background-color: #FC0C1B;
  }
}


.metric-content.metric-bars:not(:last-child),
.metrics-item.metric-bars:not(:last-child) {
  border-bottom: 0;
  padding-bottom: 0;
}

.metric-content.metric-bars,
.metrics-item.metric-bars {
  .item-name {
    font-weight: 500;
    color: $secondary-color;

    .responses-count {
      margin-left: 2rem;
      color: $text-color;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .metric-bar {
    .bar {
      min-width: 30px;
      height: 5rem;
      border-radius: 2px;
      color: $white-color;
      font-weight: 500;
      font-size: 14px;
      line-height: 5rem;
      text-align: center;
    }
  }
}

.topbar-actions {
  position: relative;
  display: flex;

  .btn-icon {
    margin-left: 2rem;

    i {
      color: $white-color;
    }
  }
}

.content-wrapper {
  margin-bottom: 6rem;

  &.two-columns {
    display: flex;
  }

  .content-widget.summary {
    margin-bottom: 6rem;  
    // border-radius: 6px;
    overflow: hidden;
  }

  .module-wrapper {
    h2 {
      margin-top: 0;
      font-size: 18px;

      &.comparative {
        margin-top: 6rem;
      }
    }

    .buttons-right {
      text-align: right;
    }

    .two-columns {
      display: flex;

      .field {
        flex: 1;

        &:first-child {
          margin-right: 6rem;
        }
      }

      .column-a {
        flex: 1;
      }
      
      .column-b {
        flex: 1;
        border-left: 1px #d4d6d7 solid;
      }

      .metrics-item {
        flex: 1;
      }
    }
  }

  .processing-test-warning,
  .processing-data-warning {
    display: flex;
    align-items: center;

    &>i {
      margin-right: 3rem;
    }

    h3 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 2rem;
      color: $white-color;

      span {
        margin-right: 1rem;
      }
    }
    p {
      font-size: 13px;
      margin-top: 0;
    }
  }

  .processing-data-warning {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 6rem;
  }

  .processing-data-warning-button {
    text-align: center;

    .button-text {
      display: flex;
      align-items: center;

      i {
        margin-left: 1rem;
      }
    }
  }
}

.module-wrapper {
  margin-bottom: 6rem;
  border-right: 1px $gray-color-2 solid;
  border-left: 1px $gray-color-2 solid;
  border-bottom: 1px $gray-color-2 solid;

  .module-title {
    display: flex;
    align-items: center;
    padding: 3rem 3rem;
    border-top: 1px $gray-color-2 solid;
    border-bottom: 1px $gray-color-2 solid;
    background: white;

    .position {
      font-size: 15px;
      background: #edeeee;
      margin-right: 3rem;
      display: inline-block;
      width: 28px;
      height: 28px;
      text-align: center;
      border-radius: 2px;
      line-height: 28px;
    }

    h2 {
      flex: 1;
      color: $text-color;
      text-align: left;
      margin: 0;
      font-size: 22px;
      font-size: 18px;
      font-weight: 400;
      display: inline-block;
      position: relative;
      top: 1px;

      .tech {
        background-color: $gray-color-1;
        color: rgba($secondary-color, .6);
        font-weight: 400;
        font-size: 12px;
        text-transform: capitalize;
        padding: 0.3rem 1rem;
        margin-left: 3rem;
        border-radius: 2px;
      }
    }

    .btn-toggle-media-preview {
      width: auto;
      display: flex;

      .label {
        font-size: 13px;
        font-weight: 500;
        line-height: 30px;
        margin-right: 2rem;
      }

      i {
        // font-size: 16px;
        // position: relative;
        // top: 4px;
        font-size: 18px;
        position: relative;
        top: 1px;
      }

      .button-toggle {
        position: relative;
        top: 6px;
      }
    }
  }

  .metrics-download {
    position: relative;
    cursor: pointer;

    .btn-open-actions {
      padding-right: 2rem;
    }

    .metrics-download-options {
      position: absolute;
      background: $white-color;
      border: 1px $gray-color-2 solid;
      color: $text-color;
      z-index: 10;
      left: 0;
      top: 5rem;
      text-align: left;
      width: max-content;
      max-width: 300px;
      padding: 2rem 0;
      box-shadow: 0 0 8px rgba(#000, 0.19);

      button,
      button.inline {
        height: inherit;
        margin: 0;
        width: 100%;
        font-size: 12px;
        padding: 2.5rem 4rem;
        text-align: left;
        color: $text-color;
        line-height: inherit;
        display: block;

        i::before {
          margin: 0 2rem 0 0;
        }
      }

      button:not(:disabled):hover * {
        color: $main-color;
      }
    }
  }
}

.float-bottom {
  border-top: 1px $gray-color-2 solid;
  box-shadow: 0 0 8px #00000030;
}

.fixed-bottom.metrics-send-link {
  padding: 0 3rem;
  text-align: left;
  border-top: 1px $gray-color-2 solid;
  box-shadow: 0 0 8px #00000030;
  position: relative;
  z-index: 2;
  height: 61px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  .header {
    border-bottom: 0px $gray-color-2 solid;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    height: 60px;

    h3 {
      flex: 1;
      font-size: 18px;
      margin: 0;
      font-weight: 400;
      color: $secondary-color;
    }

    i {
      font-size: 22px;
      color: $text-color;
    }
  }

  p {
    margin-top: 3rem;
    margin-bottom: 0;
    font-size: 12px;
  }

  h2 {
    margin: 4rem 0 3rem;
  }
}

.metrics-send-link.open {
  height: 196px;
  height: 316px;
  padding-bottom: 3rem;
}

.test-options-menu {
  position: absolute;
  background: #ffffff;
  border: 1px #D4D6D7 solid;
  color: #777782;
  z-index: 4;
  right: 0rem;
  top: 7rem;
  text-align: left;
  width: 160px;
  padding: 2rem 0;
  border-radius: 2px;

  &:before {
    content: '';
    border-left: 8px $gray-color-2 solid;
    border-top: 8px $gray-color-2 solid;
    border-bottom: 8px transparent solid;
    border-right: 8px transparent solid;
    width: 0;
    height: 0;
    transform: rotate(46deg);
    position: absolute;
    right: 14px;
    top: -8px;
  }

  &:after {
    content: '';
    border-left: 8px $white-color solid;
    border-top: 8px $white-color solid;
    border-bottom: 8px transparent solid;
    border-right: 8px transparent solid;
    width: 0;
    height: 0;
    transform: rotate(46deg);
    position: absolute;
    right: 14px;
    top: -6px;
  }

  button {
    height: inherit;
    margin: 0;
    width: 100%;
    font-size: 12px;
    padding: 2.5rem 4rem;
    text-align: left;
    color: $text-color;
  }

  button:hover {
    color: $main-color;
  }

  .separator {
    margin: 1rem 4rem;
    padding: 0;
    border-bottom: 1px $gray-color-1 solid;
  }
}

.test-item {
  padding: 16px 0 0;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.test-card {
  position: relative;
  background: $white-color;
  border-radius: 2px;
  border: 1px $gray-color-2 solid;

  .status-label {
    position: absolute;
    top: -16px;
    left: 4rem;
    display: inline-block;
    background: $gray-color-2;
    color: $white-color;
    font-size: 13px;
    padding: 0 2rem;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;

    &.open {
      background: #68C53E;
    }

    &.processing {
      background: #FECB45;
    }
  }

  .card-footer {
    display: flex;
    padding-left: 4rem;;

    .responses {
      flex: 1;
      display: flex;
      align-items: center;

      i {
        margin: 0;
        color: $secondary-color;
        font-size: 18px;
      }

      p {
        margin: 0 0 0 2rem;
        color: $secondary-color;
        font-size: 16px;
        font-weight: 600;

        span {
          color: $text-color;
          font-weight: 400;
        }
      }
    }

    button.inline {
      padding: 0 4rem;
      font-size: 12px;
      height: 46px;
      line-height: 46px;

      &:hover {
        color: $main-color;
      }
    }
  }

  .card-content {
    display: flex;
    text-align: left;
    padding: 5rem 4rem 0;

    .buttons-wrapper {
      margin-bottom: 0;
      margin-top: 0;
    }

    .button-icon {
      width: 42px;
      text-align: center;

      i {
        font-size: 18px;
        color: $text-color;
      }

      &:hover {
        cursor: pointer;

        i {
          color: $main-color;
        }
      }
    }

    .title-wrapper {
      flex: 1;

      .title {
        font-size: 18px;
        line-height: inherit;
        font-weight: 400;
        color: $secondary-color;

        &:hover {
          // cursor: pointer;
        }
      }

      .creation-date {
        margin-top: 1rem;
        font-size: 12px;
        font-weight: 400;
        color: $text-color;
      }
    }
  }
}

.tests-wrapper {
  margin-top: 5rem;
}

.only-mobile {
  display: none;
}

.wizard {
  background: $white-color;
}

.app.lite {
  max-width: 360px;
  margin: auto;

  .fluid-wrapper {
    padding: 3rem 4rem 0;
  }

  .button,
  button.inline {
    height: 46px;
    line-height: 46px;
    font-size: 13px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.desktop-promo-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;

  .desktop-box {
    margin-top: 4rem;
    padding: 2rem;
    background: $gray-color-1;

    p {
      font-size: 14px;
    }
  }

  .button-close {
    width: 40px;
    text-align: right;

    i {
      color: $gray-color-2;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .header {
    display: flex;
  }

  h2 {
    margin-bottom: 4rem;
    flex: 1;
  }

  p {
    margin: 0;

    &.icon-text {
      margin-bottom: 1rem;
      font-weight: 600;

      i {
        font-size: 18px;
        display: inline-block;
        width: 32px;
        text-align: left;
      }
    }

    &.icon-text:not(:first-child) {
      margin-top: 3rem;
    }
  }

  .cover {
    background: #6b8286;
    opacity: 0.8;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    background: #ffffff;
    border: 0px #D4D6D7 solid;
    height: auto;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    padding: 3rem;
    box-sizing: border-box;
    width: calc(100% - 6rem);
  }
}

.video-timeline.have-comparative {
  .segment-name {
    margin: 0;
    display: block !important;
    background: $gray-color-1;
    padding: 2rem 3rem;
    border: 1px $gray-color-2 solid;
  }

  .blink-timeline.segment-a {
    background: red;
    .times .label {
      display: none;
    }
  }   

  .position-bar {
    height: 521px !important;
  }
}

.blink-timeline {
  margin-top: 16rem;
  // margin-top: 37rem;
  height: 40px;

  &.flashes {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 160px);
  }

  .timeline {
    position: relative;
    height: 15px;
    width: 100%;
    background-color: $gray-color-2;
    // border-radius: 8px;

    // &:hover::before {
    //   content: '';
    //   background: #edeeeec2;
    //   width: 120%;
    //   height: 160px;
    //   position: absolute;
    //   top: -160px;
    //   left: -10%;
    //   z-index: 2;
    //   pointer-events: none;
    // }

    .thumbnails {
      display: none;
      // display: flex;
      position: absolute;
      bottom: 15px;

      div {
        flex: 1;

        img {
          width: 100%;
        }
      }
    }

    .times {
      height: 15px;
      display: flex;
      font-size: 11px;
      width: 100%;
      justify-content: space-between;
      position: absolute;
      top: 10px;

      div {
        position: relative;
        height: 70%;
        width: 2px;
        background-color: $gray-color-2;

        .label {
          background: none;
          width: 50px;
          text-align: center;
          position: absolute;
          top: 16px;
          left: 50%;
          transform: translateX(-50%);
        }

        &.featured {
          height: 100%;
          background-color: $gray-color-2;
        }

        &.first {
          left: 0;
        }

        &.last {
          right: 0;
        }
      }
    }

    .track {
      position: absolute;
      height: 100%;
      background-color: $main-color;
      color: $main-color;
      // border-radius: 8px;

      &:hover {
        cursor: pointer;

        .label,
        &:before,
        .popup {
          z-index: 2;
          opacity: 1;
        }

        .thumbnail {
          /* transform: scale(1); */
          z-index: 4;
        }
      }

      &.peak {
        z-index: 1;
        background-color: #9e3ec5;
        color: #9e3ec5;
        margin-left: -3px;

        .label {
          background-color: #9e3ec5;
          color: $white-color;
        }

        &:hover {
          z-index: 3;
        }

        &:before {
          background-color: #9e3ec5;
        }

        .popup {
          background-color: #9e3ec5;
          border-color: #9e3ec5;

          .title {
            background: #9e3ec5
          }

          &:before {
            border-right-color: #9e3ec5;
            border-bottom-color: #9e3ec5;
          }
        }
      }

      &.poor {
        background-color: #FECB45;
        color: #FECB45;

        .label {
          background-color: #FECB45;
          color: $white-color;
        }

        &:before {
          background-color: #FECB45;
        }

        .popup {
          background-color: #FECB45;
          border-color: #FECB45;

          .title {
            background: #FECB45
          }

          &:before {
            border-right-color: #FECB45;
            border-bottom-color: #FECB45;
          }
        }
      }

      &.good {
        z-index: 1;
        background-color: #68C53E;
        color: #68C53E;

        .label {
          background-color: #68C53E;
          color: $white-color;
        }

        &:hover {
          z-index: 3;
        }

        &:before {
          background-color: #68C53E;
        }

        .popup {
          background-color: #68C53E;
          border-color: #68C53E;

          .title {
            background: #68C53E
          }

          &:before {
            border-right-color: #68C53E;
            border-bottom-color: #68C53E;
          }
        }
      }

      &.bad {
        background-color: #FC0C1B;
        color: #FC0C1B;

        .label {
          background-color: #FC0C1B;
          color: $white-color;
        }

        &:before {
          background-color: #FC0C1B;
        }

        .popup {
          background-color: #FC0C1B;
          border-color: #FC0C1B;

          .title {
            background: #FC0C1B
          }

          &:before {
            border-right-color: #FC0C1B;
            border-bottom-color: #FC0C1B;
          }
        }
      }

      &.no-data {
        z-index: 1;
        background-color: #b3b3b3;

        .label {
          background-color: #68C53E;
          color: $white-color;
        }

        &:hover {
          z-index: 3;
        }

        &:before {
          background-color: #b3b3b3;
        }

        .popup {
          background-color: #b3b3b3;
          border-color: #b3b3b3;

          .title {
            background: #b3b3b3
          }

          &:before {
            border-right-color: #b3b3b3;
            border-bottom-color: #b3b3b3;
          }
        }
      }

      &:before {
        // content: '';
        // position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        // background-color: $main-color;
        // width: 1px;
        // height: 45px;
        // opacity: 0;
        // transition: all 0.2s;
      }

      .first {
        transform: translateX(-50%);
      }

      .last {
        transform: translateX(50%);
      }

      .popup {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        bottom: 30px;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
        border-radius: 2px;
        opacity: 1;
        transition: all 0.2s;
        width: 165px;
        background: $white-color;
        border-radius: 2px;
        border: 2px $gray-color-2 solid;
        // padding: 1rem;

        .title {
          background: #68c53e;
          // margin-left: -1rem;
          // margin-top: -1rem;
          // margin-right: -1rem;
          padding: 1rem 1rem;
          color: #fff;
          // margin-bottom: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .subtitle {
          text-align: left;
          color: $secondary-color;
          font-size: 11px;
          font-weight: 500;
          margin-bottom: 1rem;
        }

        &:before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -6px;
          transform: translateX(-50%) rotate(45deg);
          border-top: 6px transparent solid;
          border-right: 6px $gray-color-2 solid;
          border-bottom: 6px $gray-color-2 solid;
          border-left: 6px transparent solid;
          width: 0px;
          height: 0px;
        }

        // &:after {
        //   content: '';
        //   position: absolute;
        //   left: 50%;
        //   bottom: -5px;
        //   transform: translateX(-50%) rotate(45deg);
        //   border-top: 5px transparent solid;
        //   border-right: 5px $white-color solid;
        //   border-bottom: 5px $white-color solid;
        //   border-left: 5px transparent solid;
        //   width: 0px;
        //   height: 0px;
        // }

        img {
          width: 100%;
        }
      }

      .label {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        margin-top: 45px;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
        padding: 2px 1rem;
        border-radius: 2px;
        opacity: 1;
        transition: all 0.2s;
      }

      &.outside-left {
        .popup {
          transform: translateX(-8px);

          &:before {
            left: 1px;
            transform: rotate(45deg);
          }

          &:after {
            left: 2px;
            transform: rotate(45deg);
          }
        }
      }

      &.outside-right {
        .popup {
          transform: translateX(8px);
          left: initial;
          right: 50%;

          &:before {
            left: initial;
            right: 1px;
            transform: rotate(45deg);
          }

          &:after {
            left: initial;
            right: 2px;
            transform: rotate(45deg);
          }
        }
      }

      // .thumbnail {
      //   width: 100px;
      //   height: 60px;
      //   background-color: $white-color;
      //   transform: translateX(-50%);
      //   position: absolute;
      //   bottom: 30px;
      //   transition: all 0.2s;
      //   z-index: 0;
      //   left: 50%;
      //   border: 1px $gray-color-2 solid;
      //   border-radius: 2px;
      // }

      // .label {
      //   display: none;
      // }
    }
  }
}

.loader-widget {
  width: 100%;
  text-align: center;
  height: 100px;
  display: flex;
  align-items: center;

  .rai-activity-indicator {
    margin: auto;
  }
}

.metric-content.good .metric-bar .bar,
.metric-content.medium .metric-bar .bar,
.metric-content.bad .metric-bar .bar,
.metrics-item.good .metric-bar .bar,
.metrics-item.medium .metric-bar .bar,
.metrics-item.bad .metric-bar .bar {
  background: $main-color;
}

/*     .bar-3 {
      background-color: #EC8352;
    }

    .bar-4 {
      background-color: #F39F4D;
    }

    .bar-5 {
      background-color: #F9B859;
      */

.test-plans {
  display: flex;
  margin-bottom: 6rem;

  .test-plan {
    flex: 1 1;
    margin-right: 3rem;
    padding-right: 3rem;
    border-right: 1px $gray-color-2 solid;
    display: flex;
    flex-direction: column;

    .description {
      flex: 1;
    }

    .plan-title {
      font-size: 18px;
      font-weight: 600;
      color: $secondary-color;
    }

    .plan-name {
      font-size: 15px;
      font-weight: 400;
      color: $secondary-color;
    }

    .plan-price-from {
      margin-top: 2rem;
      margin-bottom: -2rem;
      display: block;
      font-size: 12px;
      text-align: center;  
    }

    .plan-price-custom {
      margin-bottom: 6rem;
    }

    .plan-price {
      margin-top: 2rem;
      margin-bottom: 4rem;
      font-size: 26px;
      text-align: center;
    }

    .plan-tag {
      // background-color: $secondary-color;
      background-color: #2f6e94;
      color: $white-color;
      text-transform: uppercase;
      font-weight: 600;
      padding: 1rem 0;
      margin: 3rem 0;
      text-align: center;

      &.free {
        // background-color: $main-color;
        background-color: #3ebfb8;
      }

      &.custom {
        /* background-color: $featured-color; */
      }

      &.active {
        border: 1px #02c6e6 solid;
        padding: 2rem 3rem;
        margin: -11px 3rem -2rem -2rem;
        border-radius: 4px;
      }
    }

    .plan-description {
      margin-bottom: 6rem;
      font-weight: 400;
      font-size: 12px;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: 0;
    }

    &.active {
      border: 1px solid #00C7E7;
    }

    button {
      margin-top: 3rem;
      height: 42px;

      &.inline {
        // color: #00c7e7;
        text-transform: uppercase;
      }
    }
    
  }
}

.name-item {
  flex: 2;
  margin-right: 6rem;
}


.language-item {
  flex: 1;
}

.button-with-loader {
  &:disabled {
    color: $main-color;
  }
}

.create-test-button {
  text-align: right;
  margin-top: 6rem;
  margin-bottom: 6rem;

  .button {
    height: 46px;
  }

  button:disabled {
    color: $main-color;
  }
}

.create-test {
  .content-widget {
    margin-bottom: 3rem;  

    &.invalid {
      border-color: $error-color;
    }
  }

  .content-widget.first {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .back-buttom {
    width: 60px;  
  }

  .create-test-note {
    font-size: 11px;
    float: left;
  }
}

.no-events {
  pointer-events: none;
}







































































@media (max-width: 1024px) {
  .only-desktop {
    display: none;
  }

  .only-mobile {
    display: block;
  }

  #fg_bubble {
    // visibility: hidden !important;
  }

  .fixed-bottom {
    padding: 4rem;
  }

  .field .input-wrapper input {
    height: 46px;
  }

  .notification {
    p {
      font-size: 14px;
    }
  }

  .module-wrapper {
    border-left: 0;
    border-right: 0;
    margin-bottom: 2rem;
  }

  .screen-login {
    .button {
      margin-bottom: 4rem;
    }

    .logo-wrapper .logo {
      margin: 0 auto;
    }

    .login-box {
      height: 100%;
      max-width: 500px;
      padding: 0 4rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 18px;
      }
    }

    .logo-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  .button,
  button.inline {
    height: 46px;
    line-height: 46px;
    font-size: 13px;
    padding: 0;
  }

  .app:not(.screen-metrics) {
    max-width: inherit;
  }

  .app {
    height: 100%;
    display: flex;
    flex-direction: column;

    input {
      height: 46px;
    }

    .loading-cover {
      .rc-progress-circle {
        position: relative;
        width: 200px;

        .rc-progress-circle-trail {
          opacity: 0.1;
        }
      }

      .loading-text {
        color: $white-color;
        position: absolute;
        text-align: center;
        font-size: 16px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .animated {
          color: $white-color;
          font-size: 28px;
          line-height: 16px;
          height: 16px;
          margin-top: 2rem;
          margin-bottom: 0;
        }
      }
    }
  }

  .wizard {
    background: $background-color;
  }

  .app.lite {
    max-width: inherit;
  }

  .fluid {
    flex: 1;
    text-align: center;
    position: relative;
    overflow: auto;
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .screen-content {
    padding-bottom: 0;
  }

  .content-wrapper {
    margin-bottom: 0;
  }

  .screen-topbar,
  .content-header {
    display: none;
  }

  .mobile-topbar {
    display: initial;
  }

  .metrics-send-link.open {
    display: initial;
  }

  .tests-wrapper {
    margin-top: 0;
  }

  .test-card {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  .screen-content.add-padding-top {
    padding-top: 2rem;
  }

  .test-card {
    .card-footer {
      button.inline {
        font-size: 14px;
      }
    }
  }

  .buy-audience .buy-audience-right .content-widget {
    margin-bottom: 0;
  }

  // body.wizard {
  //   display: flex;
  //   align-items: center;
  //   background: $white-color !important;

  //   #root {
  //     height: auto;
  //     margin: auto
  //   }

  //   .lite {
  //     background: $white-color;
  //     max-width: 400px;
  //     // min-height: 600px;
  //     margin: auto;
  //     padding-top: 12rem;
  //   }

  //   .rc-dialog-root .rc-dialog-content {
  //     max-width: 500px;
  //   }

  //   .file.margin-bottom {
  //     margin-bottom: 12rem;
  //   }
  // }

  // body.wizard.metrics {
  //   background: $secondary-color !important;

  //   .lite,
  //   .lite .lite-topbar {
  //     background: $secondary-color;
  //   }

  //   .lite .fixed-bottom {
  //     border-radius: 2px;
  //   }
  // }
}


@media (max-width: 1440px) {
  .global-summary {
    flex-wrap: wrap;
    margin-bottom: 2rem;

    div {
      min-width: 33.3%;
      margin-bottom: 4rem;
    }
  }

  .question-results {
    .answers-legend {
      ul {
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 1260px) {
  .test-item-options {
    left: unset;
    right: 0rem;
  }

  .test-item-options:before {
    left: unset;
    right: 27px;
  }

  .test-item-options:after {
    left: unset;
    right: 28px;
  }
}

@media (max-width: 750px) {
  .content-wrapper .module-wrapper .two-columns {
    flex-direction: column;
  }
}

@media (max-height: 750px) {
  .rc-dialog-root .rc-dialog-body {
    margin-top: 0;
  }

  .subscriptions-modal .subscription-period-selector-wrapper {
    margin-top: 4rem;

    .selector-button {
      font-size: 12px;
      padding: 2rem 3rem;
    }

    .discount {
      font-size: 10px;
    }
  }

  .subscriptions-modal .subscription-plans {
    margin-bottom: 0rem;
    margin-top: 10rem;
  }

  .subscriptions-modal .subscriptions-modal-title {
    margin-top: 0rem;
    margin-bottom: 4rem;
    font-size: 20px;
  }

  .subscriptions-modal .subscriptions-modal-subtitle p {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  .subscriptions-modal .subscription-plans .plan-box .plan-characteristics {
    margin-bottom: 3rem;
  }

  .subscriptions-modal .subscription-plans .plan-box .plan-title {
    margin-top: 3rem;
  }
}

@media (max-height: 620px) {
  body .subscriptions-modal-root .rc-dialog-content {
    transform: translate(-50%, -50%) scale(0.9);
  }
}

@media (max-width: 1079px) {
  .col {
    flex-direction: column;
  }

  .two-columns .col {
    flex: 1;
  }
}

@media (max-width: 620px) {
  .notification {
    width: 100%;
    padding: 3rem 2rem 4rem 5rem;
  }

  .notification.visible {
    transform: translate(-50%, 0);
  }

  .rc-dialog-root .rc-dialog-content {
    width: calc(100% - 8rem);
    min-width: 0;

    input {
      height: 46px;
    }
  }

  .rc-dialog-root .rc-dialog-close {
    right: -2rem;
    top: -10rem;
  }
}

@media (max-height: 600px) {
  .desktop-promo-modal .modal {
    transform: none;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }
}