$main-color: #00C7E7;
$secondary-color: #1C1D2E;
$featured-color: #FB8500;
$error-color: #EF233C;
$success-color: #04D973;
$white-color: #ffffff;

$text-color: #777782;
$background-color: #EDEEEE;

$gray-color-1: #EDEEEE;
$gray-color-2: #D4D6D7;

.module-type-info {
    background: $secondary-color;
    border-radius: 2px;
    color: #ffffff;
    font-size: 11px;
    display: flex;
    align-items: center;
    padding: 2rem 3rem;
    margin-top: -2rem;
    margin-bottom: 6rem;

    img {
        width: 40px;
        margin: auto;
        margin-right: 3rem;
    }

    p {
        flex: 1;
        margin: 0;
    }
}