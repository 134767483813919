$main-color: #00C7E7;
$secondary-color: #1C1D2E;
$featured-color: #FB8500;
$error-color: #EF233C;
$success-color: #04D973;
$white-color: #ffffff;

$text-color: #777782;
$background-color: #EDEEEE;

$gray-color-1: #EDEEEE;
$gray-color-2: #D4D6D7;

.panel > .flex {
    height: 100%;
}

.screen-content-inner:not(.show-right-panel) .panel {
    // transition: right 0.3s ease-in-out;
}

.panel {
    display: flex;
    flex-direction: column;
    background: $background-color;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-left: 1px $gray-color-2 solid;
    box-sizing: border-box;
    /* box-shadow: 0px 2px 20px #00000052; */
    padding: 6rem 0 0;
    z-index: 2;

    &.panel-film-screen,
    &.panel-video-screen,
    &.panel-audio-screen,
    &.panel-image-screen {
        .loading-cover {
            .loading-text, .loading-text .animated {
                color: $secondary-color;
                width: 100%;
            }

            .loading-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
        
                .progress-text {
                    position: relative;
                }

                .cancel-button {
                    text-align: center;
                    margin-top: 5rem;
                    z-index: 10;

                    &.cancel-button-hidden {
                        visibility: hidden;
                    }

                    button {
                        font-size: 14px;
                        color: $error-color;

                        &:hover {
                            opacity: .6;
                        }
                    }
                }
            }

            .cover {
                background: #ffffff;
                opacity: 1;
            }
        }
    }

    .loading-cover {
        .cover {
            background: #ffffff;
            opacity: 0.6;
        }
    }

    .field:last-child {
        margin-bottom: 6rem;
    }

    .panel-title {
      font-size: 18px;
      font-weight: 400;
      color: $secondary-color;
      padding: 0 0 4rem;
      margin: 0 6rem;
      border-bottom: 1px $gray-color-2 solid;

      button.secondary {
        /* border: 0; */
      }

      .right {
        float: right;

        .main-button {
            background: #262730;
        }

        .main-button:disabled {
            opacity: 0.3;
        }

        .button.secondary {
            .button-text {
                margin-top: -1px;
            }

            i {
                font-size: 12px;
                margin-right: 1rem;
            }
        }
      }
    }

    .panel-subtitle {
      margin: 1.5rem 0 0;
      font-size: 12px;
      font-weight: 400;
      color: #7f7d86;
    }

    .panel-inputs {
        margin-top: 0;
        overflow: auto;
        flex: 1;
        /* height: calc(100% - 56px); */
        box-sizing: border-box;
        padding: 4rem 6rem 0;

        .scroll-content {
            padding: 4rem 6rem 0;
        }

        #sortable-b li:last-child {
            margin-bottom: 8rem;
        }

        .field.disabled {
            pointer-events: none;
            :not(label) {
                opacity: .6;
            }
        }

        .select.disabled {
            pointer-events: none;
            opacity: .6;
        }
    }

    .panel-bottom {
        border-top: 1px $gray-color-2 solid;
        padding-top: 5rem;
        text-align: right;
    }

    h3 {
        button:not(.no-padding) {
            padding: 0 2rem;
        }
        button:not(.main-button) {
            background: transparent;

            i {
                color: $text-color;
                font-size: 15px;
            }
        }
        button:not(.main-button):hover {
            background: transparent;
        }
    }

    .field label {
        display: flex;
        align-items: flex-end;

        p {
            flex: 1;
            margin: 0;
        }

        button {
            margin-left: 2rem;
        }
    }

    .qa-buttons {
        /* float: right; */
        margin-top: 4rem;

        button {
            margin-left: 0rem;
            width: 100%;
        }
    }

    .row {
        margin: 0;
        width: 100%;

        .col {
            margin: 0;
            display: flex;
            flex-direction: column;
        }
    }

    .panel-bottom {
        padding: 0;
        margin: 0 6rem;
    }

    .questions-pagination {
        margin-top: 3rem;
        margin-bottom: 4rem;
        display: flex;
        align-items: center;

        .pagination-item {
            width: 10px;
            height: 10px;
            background: $secondary-color;
            border-radius: 5px;
            opacity: 0.3;
            margin: 0 1rem;
        }

        .pagination-item.active {
            opacity: 1;
        }

        .pagination-item:hover {
            cursor: pointer;
        }
    }

    .qa-draggable .field {
        margin: 1rem 0;
        flex: 1;
    }

    .loading-cover {
        position: absolute;
    }
}

.section-modules .panel {
    position: inherit;
    box-shadow: none;
    background: transparent;
    border: 0;
    padding: 0;
}

.section-modules {
    .loading-cover {
        position: absolute;
    }

    .loading-cover .cover {
        background: #ffffff;
        opacity: 0.6;
    }
}

.screen-content {
    .panel-open-add-screen #scrollview {
        width: calc(100% - 311px);
    }

    .panel-open-text-screen #scrollview,
    .panel-open-video-screen #scrollview,
    .panel-open-film-screen #scrollview,
    .panel-open-audio-screen #scrollview,
    .panel-open-image-screen #scrollview,
    .panel-open-qa-screen #scrollview {
        width: calc(100% - 610px);
        width: calc(100% - 360px);
    }

    .panel-open-add-screen,
    .panel-open-text-screen,
    .panel-open-video-screen,
    .panel-open-film-screen,
    .panel-open-audio-screen,
    .panel-open-image-screen,
    .panel-open-qa-screen {
        .add-module-float-button {
            opacity: 0;
            transition: opacity 0.2s;
            transition-delay: 0s;
        }
    }


    .panel-open-add-screen {
        .panel-add-screen {
            right: 0px;
        }
    }

    .panel-open-text-screen {
        .panel-text-screen {
            right: 0px;
        }
    }

    .panel-open-video-screen {
        .panel-video-screen {
            right: 0px;
        }
    }

    .panel-open-film-screen {
        .panel-film-screen {
            right: 0px;
        }
    }

    .panel-open-audio-screen {
        .panel-audio-screen {
            right: 0px;
        }
    }

    .panel-open-image-screen {
        .panel-image-screen {
            right: 0px;
        }
    }

    .panel-open-qa-screen {
        .panel-qa-screen {
            right: 0px;
        }
    }

    .panel-text-screen,
    .panel-video-screen,
    .panel-film-screen,
    .panel-audio-screen,
    .panel-image-screen,
    .panel-qa-screen {
        width: 610px;
        right: -630px;

        width: 360px;
        right: -380px;

        .scrollbar-track-y .scrollbar-thumb {
            left: -2rem;
        }
    }

    .external-link {
        > .row {
            margin-top: 6rem;

            &.separator {
                padding: 4rem 0 0 0;
                border-top: 1px #D4D6D7 solid;
                border-bottom: 0;
            }
        }

        .field {
            margin-top: 3rem;
        }

        label.full-width {
            // color: #1C1D2E;
            // font-size: 13px;
            // font-weight: 400;
            margin-bottom: 2rem;
        }
    }

    /*
    .panel-add-screen {
        width: 320px;
        right: -340px;
    }
    */

    .panel-qa-screen .qa-draggable {
        margin-bottom: 6rem;    
    }
}

.draggable-list li.disabled {
    opacity: .6;
    pointer-events: none;
    .button-close:hover {
      opacity: 0.4;
    }
}

/* Version control */
.version-panel {
    position:fixed;
    height: 0;
    width: 0;
   
    .version-panel-inner {
        right: -380px;
    }
}

.version-panel-open {
    z-index: 9;
    width: 100%;
    height: calc(100% - 60px);
    position: absolute;

    .version-panel-mask {
        position: absolute;
        top: 0;
        left: 0;
        background: #6b8286;
        opacity: 0.8;
        width: 100%;
        height: 100%;

        &.disabled {
            pointer-events: none;
        }
    }

    .version-panel-inner {
        position: absolute;
        right: 0;
        height: 100%;
        width: 360px;
        background: $gray-color-1;
        overflow-y: scroll;
        transition: right .3s ease-in-out;

        .panel-title {
            font-size: 18px;
            font-weight: 400;
            color: #1C1D2E;
            padding: 6rem;
            border-bottom: 1px $gray-color-2 solid;
            background-color: $white-color;
            margin: 0;

            i {
                color: $text-color;
            }
        }

        &>p {
            padding: 0 6rem 0 6rem;
        }

        .versions {
            border-bottom: 1px $gray-color-2 solid;

            .version {
                padding: 0 6rem 0 6rem;

                .version-date span {
                    font-weight: 500;
                    cursor: pointer;

                    &:hover, &:hover i::before {
                        color: $main-color;
                    }

                    i {
                        font-size: 13px;
                    }
                } 
            }
        }

        .loader-scroll {
            text-align: center;
            margin: 3rem 0;
        }

        .loading-cover {
            position: absolute;
        }

        .loading-cover .cover {
            background: $gray-color-1;
            opacity: .6;
        }
    }
}