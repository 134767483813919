.notification {
    font-size: 13px;
    color: #ffffff;
    font-weight: 700;
    position: fixed;
    top: -1px;
    padding: 5rem 6rem;
    text-align: center;
    box-sizing: border-box;
    z-index: 99;
    transition: transform 300ms ease-in-out;
    display: flex;
    text-align: center;
    align-items: center;
    border-radius: 2px;
    /* max-width: 600px; */
    margin: auto;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 2rem 2rem 2rem 5rem;

    button {
        margin-left: 2rem;

        i {
            color: #ffffff;
            opacity: 0.6;
            font-size: 11px;
            transition: opacity 0.2s;
        }
    }

    button:hover i {
        opacity: 0.8;
    }
}

.notification.visible {
    transform: translate(-50%, 4rem);
}

.notification p {
    max-width: none !important;
    margin: 1px auto;

    span {
        font-weight: 400;
    }

    a {
        color: #ffffff;
    }
}