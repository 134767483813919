$main-color: #00C7E7;
$secondary-color: #1C1D2E;
$featured-color: #FB8500;
$error-color: #EF233C;
$warning-color: #FFB703;
$success-color: #04D973;
$white-color: #ffffff;

$text-color: #777782;
$background-color: #EDEEEE;

$gray-color-1: #EDEEEE;
$gray-color-2: #D4D6D7;


#invite-audience {
  text-align: left;

  @media (max-width: 1024px) {
    max-width: 730px;
    margin: auto;
  }

  .invite-audience-email-configuration {

    .content-widget {
      position: relative;
      margin-bottom: 6rem;
      padding-bottom: 5rem;

      .name-test {
        flex: 1;
        margin-right: 6rem;
      }

      .name-sender {
        flex: 1;
      }

      .email-configuration-button {
        margin-left: 5rem;
        justify-self: flex-end;
        align-self: flex-end;
      }

      .field {
        margin-bottom: 0;

        .input-wrapper input:read-only {
          cursor: default;
          opacity: .6;
        }
      }

      .loading-cover {
        position: absolute;
      }

      .loading-cover .cover {
        background: #ffffff;
        opacity: 0.6;
      }
    }
  }

  .invite-audience-description {

    @media (max-width: 1024px) {
      padding: 0 3rem;
    }
  }
}

.invite-audience-users {
  margin-top: 6rem;
  position: relative;

  .loading-cover {
    margin: 5rem 0;
    height: 30px;
  }

  .invite-audience-table {
    margin-bottom: 3rem;

    &.disabled {
      pointer-events: none;
    }

    .invite-audience-table-head,
    .invite-audience-table-row {
      display: grid;
      grid-template-columns: 60px 1fr 140px 90px 200px;
      // grid-template-columns: 60px 1fr 110px 80px 200px;
      // grid-template-columns: 60px 1fr minmax(120px, 170px) 100px 100px;
      align-content: center;
      color: $secondary-color;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      height: 46px;
      box-sizing: border-box;
      text-align: left;

      p {
        font-size: 14px;
      }

      button {
        width: 100px;
        margin-right: 2rem;

        &.main {
          color: $main-color;
          border-color: $main-color;
        }

        &:last-child {
          margin-right: 0;
        }

        &.delete {
          width: auto;
          border-color: $error-color;
          border-color: transparent;
          border: 0;
          padding: 0 2rem;

          &:hover {
            opacity: .8;
          }

          span {
            color: $error-color;
          }
        }
      }

      @media (max-width: 1024px) {
        grid-template-columns: 40px 1fr 107px;
      }

      .invite-audience-table-head-cell,
      .invite-audience-table-cell {
        box-sizing: border-box;
        padding-right: 2rem;
        align-self: center;

        &:last-child {
          // padding-right: 0;
          text-align: right;
        }
      }

      .invite-audience-table-cell.noTracking {
        color: $gray-color-2;
      }
    }

    .invite-audience-table-prehead {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 3rem;

      .field {
        margin: 0;

        label {
          all: unset;
        }
      }

      button {
        margin-top: 0;
        margin-left: 2rem;

        &:first-child {
          margin-left: 0;
        }

        &.main-button {
          i {
            color: $white-color;
            margin-top: -2px;
            margin-right: 1rem;
            margin-left: -1rem;
          }
        }

        .button-text {
          display: flex;
          padding-top: 1px;
          position: relative;
          text-align: center;
          margin: auto;
        }

        i {
          margin-top: -2px;
          margin-right: 1rem;
          margin-left: -1rem;

          &.icon-upload-1 {
            font-size: 15px;  
          }
  
          &.icon-plus-1 {
            font-size: 17px;
            margin-top: -1px;
            margin-left: -2rem;
          }
        }
      }

      .audience-actions {
        position: relative;

        .btn-open-actions {
          padding-right: 2rem;

          i {
            margin-top: -1px;
            margin-left: 1rem;
            margin-right: 0rem;
          }
        }

        .audience-actions-options {
          position: absolute;
          background: $white-color;
          border: 1px $gray-color-2 solid;
          color: $text-color;
          z-index: 4;
          left: 0;
          top: 7rem;
          text-align: left;
          width: 190px;
          padding: 2rem 0;
          box-shadow: 0 0 8px rgba(#000, 0.19);

          button,
          button.inline {
            height: inherit;
            margin: 0;
            width: 100%;
            font-size: 12px;
            padding: 2.5rem 4rem;
            text-align: left;
            color: $text-color;
            line-height: inherit;

            i {
              margin-top: -4px;
              margin-right: 2rem;
            }
          }

          button:not(:disabled):hover {
            color: $main-color;

            i {
              color: $main-color;  
            }
          }

          .separator {
            margin: 1rem 4rem;
            padding: 0;
            border-bottom: 1px $gray-color-1 solid;
          }
        }
      }
    }

    .invite-audience-table-head {
      background-color: $gray-color-2;

      .invite-audience-table-head-cell {
        color: rgba($secondary-color, .6);

        &.check {
          justify-self: center;
        }
      }
    }

    .invite-audience-table-body {
      position: relative;

      .loading-cover {
        position: absolute;
      }

      .loading-cover .cover {
        background: #ffffff;
        opacity: 0.6;
      }
    }

    .invite-audience-table-row {
      position: relative;
      background-color: $white-color;
      border-bottom: 1px solid $gray-color-2;
      border-left: 1px solid $gray-color-2;
      border-right: 1px solid $gray-color-2;

      .tag {
        padding: 1rem 2rem;
        border: 1px $gray-color-1 solid;
        border-radius: 12px;
        display: inline-block;

        color: $gray-color-2;
        border-color: $gray-color-2;

        &.secondary {
          color: $text-color;
          border-color: $text-color;
        }

        &.featured {
          color: $featured-color;
          border-color: $featured-color;
        }

        &.error {
          color: $error-color;
          border-color: $error-color;
        }

        &.good {
          color: $success-color;
          border-color: $success-color;
        }
      }

      @media (max-width: 1024px) {
        border-left: none;
        border-right: none;
        height: 100px;

        .toggle-link {
          grid-column: 2/3;
          display: flex;
          align-items: center;
          span.only-mobile {
            padding-right: 2rem;
          }
        }
      }

      .invite-audience-table-cell {

        &.check {
          justify-self: center;
        }

        &.icon {
          text-align: center;
          padding: 0;
        }

        &.finished {
          color: $success-color;
        }

        &.email {
          min-width: 120px;
          display: flex;
          flex-direction: column;
          font-weight: 400;

          p:first-child {
            margin-top: 10px;
            margin-bottom: 2px;
            word-break: break-word;
          }

          p:last-child {
            margin-top: 0px;
            margin-bottom: 10px;
          }

          p.date {
            color: rgba($secondary-color, .6);
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
          }
        }

        i {
          color: $secondary-color;
        }
      }
    }

    .button-toggle.disabled{
      opacity: .6;
      pointer-events: none;
    }

    .loading-row {
      position: relative;
      background-color: $main-color;

      .invite-audience-table-cell{
        opacity: 0;
      }
    }

    .loading-column {
      position: absolute;
      justify-content: center;

      .loading-column-cover {
        display: flex;
        align-items: center;

        span {
          padding-right: 3rem;
          font-weight: 500;
          color: $white-color;
          line-height: 11px;
        }
      }
    }
  }

  .loading-cover {
    position: absolute;

    div {
      box-sizing: border-box;
    }
  }
}

.invite-audience-users button {
  margin-top: 0;
  margin-left: 0;

  &:first-child {
    margin-left: 0;
  }

  &.main-button {
    i {
      color: $white-color;
      margin-top: -2px;
      margin-right: 1rem;
      margin-left: -1rem;
    }
  }

  .button-text {
    display: flex;
    padding-top: 1px;
    position: relative;
    text-align: center;
    margin: auto;
  }

  i {
    margin-top: -2px;
    margin-right: 1rem;
    margin-left: -1rem;

    &.icon-upload-1 {
      font-size: 15px;  
    }

    &.icon-plus-1 {
      font-size: 17px;
      margin-top: -1px;
      margin-left: -2rem;
    }
  }
}

// No testers
.no-testers-msg {
  border: 1px dashed $gray-color-2;
  padding: 5rem 4rem 6rem 4rem;

  p {
    margin-top: 0;
  }

  &-buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    button.button.secondary {
      margin-top: 0;
      margin-right: 3rem;
    }
  }
}

// Modal
.modal-subtitle {
  max-width: 500px;
}

.add-new-tester {
  // background-color: $white-color;
  border: 1px dashed $gray-color-2;
  border-radius: 0px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &-icon {
    background-color: $main-color;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 24px;
    margin-right: 2rem;

    i {
      color: $white-color;
      font-size: 18px;
    }
  }

  &-label {
    font-size: 15px;
    font-weight: 300;
    color: $secondary-color;
  }
}

.input-email {
  display:flex;
  align-items: center;
  margin-bottom: 4rem;
  position: relative;

  &:first-child {
    .button-close {
      bottom: 1rem;
    }
  }

  .field {
    margin-bottom: 0;
    flex: 1;

    .input-wrapper input {
      padding-right: 5rem;
      text-transform: lowercase;
    }
  }

  .button-close {
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    opacity: .4;

    &:hover {
      opacity: 1;
    }
  }
}

// Modal CSV
.modal-body {

  .invite-audience-users {
    .invite-audience-table-head, .invite-audience-table-row {
      grid-template-columns: 60px 1fr;
    }
  }

  .add-testers-csv {
    border: 1px solid $gray-color-2;
    border-radius: 2px;
    padding: 3rem 4rem;

    .field {
      margin-bottom: 0;
      margin-right: 4rem;
    }

    input[type="file"]{
      height: auto;
    }
  }
}

// Modal first invitation
#first-invitation-modal {
  font-size: 14px;

  h3 {
    margin: 0;
    font-weight: 500;
  }

  p {
    margin: 1rem 0 4rem;
  }

  ul {
    margin-bottom: 0;
  }
}