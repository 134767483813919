.sketch-picker {
    top: 16rem;
    right: 0;
    position: absolute;
    z-index: 10;
    background: rgb(38, 39, 48) !important;

    .flexbox-fix:last-child {
        border-top: 1px solid rgb(51, 51, 61) !important;
    }
}

.field.picker .input-wrapper:hover,
.field.picker .input-wrapper input:hover {
    cursor: pointer;
}