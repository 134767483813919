$main-color: #00C7E7;
$secondary-color: #1C1D2E;
$featured-color: #FB8500;
$error-color: #EF233C;
$success-color: #04D973;
$white-color: #ffffff;

$text-color: #777782;
$background-color: #EDEEEE;

$gray-color-1: #EDEEEE;
$gray-color-2: #D4D6D7;

.draggable-list {
    border-radius: 2px;
    padding: 0;
    margin-bottom: 3rem;
    overflow: hidden;
    max-width: 299px;

    li {
        display: flex;
        background: $white-color;
        color: $text-color;
        padding: 1px 0;
        border-bottom: 1px $gray-color-2 solid;
    }

    li:last-child {
        border-bottom: 0;
    }

    li.dragging {
        border-radius: 2px;
    }

    .placeholder {
        background: $gray-color-2;
    }

    p {
        flex: 1;
        /* max-width: 120px; */
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .button-drag {
        margin: auto;
        width: 46px;
        text-align: center;
        opacity: 0.4;
        transform: rotate(90deg);
    }

    .button-drag:hover {
        cursor: grab;
    }

    .button-drag:active {
        cursor: grabbing;
    }

    .button-close {
        margin: auto;
        width: 46px;
        text-align: center;
        opacity: 0.4;
    }

    .button-close:hover {
        cursor: pointer;
        opacity: 1;
    }
}